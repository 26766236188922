<template>
  <v-app>
    <div id="hourTooltip" v-if="hourTooltip">
      {{ hourTooltip }}
    </div>
    <b-modal id="onHoldBooking" no-fade="no-fade" size="xl" :title="$t('calendar.replace_title')" scrollable static centered no-close-on-backdrop>
      <template #modal-footer="">
        <b-button size="sm" variant="success" @click="replaceEvent">
          <i class="fas fa-exchange"></i>
          {{ $t('calendar.replace_with_selected') }}
        </b-button>
        <b-button size="sm" variant="danger" @click="deleteEvent">
          <i class="fas fa-ban"></i>
          {{ $t('calendar.just_delete') }}
        </b-button>
      </template>
      <div class="form-group chat-search-data m-0">
        <input type="text" autocomplete="off" class="form-control round" id="booking-search" :placeholder="$t('calendar.search_booking')" v-model="searchOH" @input="filteredListOnHold">
      </div>
      <div class="onHoldEvent row m-0 p-0" :class="{ activeOnHold: item.id === selectedOnHoldToken }" v-for="(item, id) in onHoldEvents" :key="id" @click="selectOnHoldBooking(item.id)" :ref="'onhold_'+item.id">
        <div class="col-sm-12">
          <span v-for="(serv, id) in item.service" :key="id"> <i class="ri-focus-line"></i> {{ serv.name }}</span>
          | {{ $t('calendar.date') }}: {{ item.start_date }}
          | {{ $t('calendar.time') }}: {{ item.start_time }}
          | {{ $t('calendar.duration') }}: {{ item.start_duration }}
        </div>

        <div class="col-sm-4 row m-0 p-0">
          <div class="col-sm-2 overflow-hidden m-0 p-0">
            <img :src="item.customer_logo" style="width: 100%"/>
          </div>
          <div class="col-sm-10 m-0 p-0">
            {{ $t('calendar.client') }}: {{ item.customer_favorit }} <br>
            {{ item.customer_name }} <br>
            <i class="fas fa-envelope"></i> <span class="send_message" v-on:click="sendMessageToCustomer(`${item.customer_email}`)">{{ item.customer_email }}</span><br>
            <span v-if="canSeePhone"><i class="fas fa-phone-square"></i> <a :href="`tel: ${item.customer_phone}`" >{{ item.customer_phone }}</a><br></span>
          </div>
        </div>
        <div class="col-sm-4 row m-0 p-0">
          <div class="col-sm-2 overflow-hidden m-0 p-0">
            <img :src="item.employee_logo" style="width: 100%"/>
          </div>
          <div class="col-sm-10 m-0 p-0">
            {{ $t('calendar.employee') }}: <br>
            {{ item.employee_fname }} {{ item.employee_lname }} <br>
            <i class="fas fa-envelope"></i> <span class="send_message" v-on:click="sendMessageToEmployee(`${item.employee_email}`)">{{ item.employee_email }}</span><br>
            <span v-if="canSeePhone"><i class="fas fa-phone-square"></i> <a :href="`tel: ${item.employee_phone}`">{{ item.employee_phone }}</a><br></span>
          </div>
        </div>
        <div class="col-sm-4">
          {{ $t('calendar.total_payment') }}: {{ item.price }} {{ item.currency }}<br>
          {{ $t('calendar.total_payed') }}: {{ item.pricePayed }} {{ item.currency}}<br>
          {{ $t('calendar.payment_method') }}: {{ item.payment_type }}
        </div>
      </div>
    </b-modal>
    <b-modal id="theCustomers" no-fade="no-fade" :title="$t('calendar.select_customer')" scrollable hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        <div class="col-sm-8">
          <input type="text" autocomplete="off" class="form-control round" id="customer-search" :placeholder="$t('calendar.search_customer')" v-model="searcheC" @input="filteredListCustomers">
        </div>
        <div class="col-sm-4 text-right">
          <b-btn @click="addCustomer">{{ $t('calendar.add_new_customer') }}</b-btn>
        </div>
      </div>
      <b-row>
        <div v-if="searcheC.length > 2 && optionsCustomers.length === 0" class="col-sm-12">{{ $t('calendar.customer_not_found') }}</div>
        <div v-if="searcheC.length <= 2" class="col-sm-12">{{ $t('calendar.type_at_least') }}</div>
        <b-form-group class="col-sm-12 employeeDetails" v-for="(emp, id) in optionsCustomers" :key="id" :ref="'customer_'+emp.id">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setCustomer(emp)">
            <b-row no-gutters>
              <b-col xs="2">
                <b-card-img :src="emp.logo || customer_image" style="height: 50px; width: auto;"></b-card-img>
              </b-col>
              <b-col xs="1"></b-col>
              <b-col xs="9">
                <div class="employeeName">{{ emp.name }}</div>
                <div>Tel. {{ emp.phone }}</div>
                <div>{{ $t('calendar.no_of_bookings') }}: {{ emp.customer_booking }}</div>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </b-row>
    </b-modal>
    <b-modal id="addCustomer" no-fade="no-fade" :title="$t('calendar.add_new_customer')" hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0 row">
        <div class="col-sm-6">
          <div class="profile-img-edit">
            <b-img :src="newuser.logo" class="height-150" alt="profile-pic"/>
            <input type="hidden" v-model="newuser.logo">
            <div class="p-image">
              <div class="position-relative">
                <i class="ri-pencil-line upload-button">
                  <input type="file" ref="fileNew" @change="previewImageNew(newuser)" class="h-100 position-absolute" accept="image/*" style="opacity: 0; left: 0" />
                </i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          {{ $t('employee.first_name') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer.customer_name')" v-model="addCustomerName" >
          {{ $t('employee.last_name') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer.customer_name')" v-model="addCustomerLastName" >
        </div>
        <div v-show="this.companyType == 'medical'">
          {{ $t('customer_.cnp') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.cnp')" v-model="addCustomerCNP" >
        </div>
        {{ $t('customer.phone') }} <br>
        <input type="text" class="form-control round mb-4" :placeholder="$t('customer.phone')" v-model="addCustomerPhone" >
        {{ $t('customer.email') }} <br>
        <input type="text" class="form-control round mb-4" :placeholder="$t('customer.email')" v-model="addCustomerEmail" >
        {{ $t('customer.password') }} <br>
        <small style="display:block; width: 100%;">{{ $t('customer_.client_password_details') }}</small><br>
        <input type="text" class="form-control round mb-4" :placeholder="$t('customer.password')" v-model="addCustomerPassword" >
        <button type="submit" class="btn btn-primary" @click="saveCustomer">{{ $t('customer.add_customer') }}</button>
      </div>
    </b-modal>
    <b-modal id="editCustomer" no-fade="no-fade" size="xl" :title="$t('extra.edit_customer')" hide-footer static centered no-close-on-backdrop>
      <template #modal-title="">
        <b-button class="ml-1 mb-1" size="sm" :variant="theVariant === 'details' ? 'success' : 'warning'" @click="theVariant = 'details'"> {{ $t('customer_.client_details') }}</b-button>
        <b-button class="ml-1 mb-1" size="sm" :variant="theVariant === 'info' ? 'success' : 'warning'" @click="theVariant = 'info'; getObservations(addCustomerToken)"> {{ $t('customer_.client_info') }}</b-button>
        <b-button class="ml-1 mb-1" size="sm" :variant="theVariant === 'docs' ? 'success' : 'warning'" @click="theVariant = 'docs'; displayDocuments(addCustomerToken)"> {{ $t('customer_.client_documents') }}</b-button>
        <b-button class="ml-1 mb-1" size="sm" :variant="theVariant === 'booking' ? 'success' : 'warning'" @click="theVariant = 'booking'; displayLastBookings(addCustomerToken)"> {{ $t('customer_.client_bookings') }}</b-button>
      </template>
      <input type="hidden" v-model="addCustomerToken"/>
      <div class="form-group chat-search-data m-0 row" v-if="theVariant === 'details'">
        <div class="col-sm-6">
          <div class="profile-img-edit">
            <b-img :src="user.logo" class="height-150" alt="profile-pic"/>
            <input type="hidden" v-model="user.logo">
            <div class="p-image">
              <div class="position-relative">
                <i class="ri-pencil-line upload-button">
                  <input type="file" ref="file" @change="previewImage(user)" class="h-100 position-absolute" accept="image/*" style="opacity: 0; left: 0" />
                </i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          {{ $t('employee.first_name') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer.customer_name')" v-model="addCustomerNameEdit" >
          {{ $t('employee.last_name') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer.customer_name')" v-model="addCustomerLastNameEdit" >
        </div>
        <div v-show="this.companyType == 'medical'">
          {{ $t('customer_.cnp') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.cnp')" v-model="addCustomerCNPEdit" >
        </div>
          {{ $t('customer.phone') }} <br>
        <input type="text" class="form-control round mb-4" :placeholder="$t('customer.phone')" v-model="addCustomerPhoneEdit" >
        {{ $t('customer.email') }} <br>
        <input type="text" class="form-control round mb-4" :placeholder="$t('customer.email')" v-model="addCustomerEmailEdit" >
        {{ $t('customer.password') }} <br>
        <small style="display:block; width: 100%;">{{ $t('customer_.client_password_details') }}</small><br>
        <input type="password" class="form-control round mb-4" :placeholder="$t('customer.password')" v-model="addCustomerPasswordEdit" >
        <button type="submit" class="btn btn-primary" v-on:click="saveCustomerEdit">{{ $t('extra.save_customer') }}</button>
      </div>
      <div v-if="theVariant === 'info'">
        <vue-editor id="editor1" v-model="editor1Content"></vue-editor>
        <br>
        <button type="submit" class="btn btn-primary" v-on:click="saveObservations(addCustomerToken)">{{ $t('customer.save') }}</button>
      </div>
      <div v-if="theVariant === 'docs'">
        <div class="text-right">
          <v-progress-linear v-if="uploadStarted" color="green" indeterminate ></v-progress-linear>
          <i class="ri-eye-fill" v-if="fileClicked && fileClicked.length > 0" @click="openFileForPreview(addCustomerToken)" style="font-size: 20px; margin-right: 20px; cursor: pointer;"></i>
          <i class="ri-delete-bin-line" v-if="fileClicked && fileClicked.length > 0" @click="deleteFile(addCustomerToken)" style="font-size: 20px; margin-right: 20px; cursor: pointer;"></i>
          <i class="ri-upload-2-fill" style="font-size: 20px; cursor: pointer;">
            <input type="file" ref="file4upload" @change="uploadFile(addCustomerToken)" class="h-100 position-absolute" accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt" style="opacity:0; margin-left:-20px; width:20px;" />
          </i>
        </div>
        <v-treeview
          style="max-height: 500px; overflow: auto;"
          v-model="tree"
          :items="items"
          activatable
          item-key="name"
          open-on-click
          @update:active="getTheFileClicked"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.file">
              {{ open ? 'ri-folder-open-line' : 'ri-folder-line' }}
            </v-icon>
            <v-icon v-else>
              {{ files[item.file] }}
            </v-icon>
          </template>
        </v-treeview>
      </div>
      <div v-if="theVariant === 'booking'">
        <v-timeline>
          <v-timeline-item v-for="(year, i) in years" :key="i" :color="year.color" small >
            <template v-slot:opposite>
              <span :class="`headline font-weight-bold ${year.color}--text`" v-text="year.start" ></span>
            </template>
            <div class="py-2">
              <h2 :class="`headline font-weight-light mb-1 ${year.color}--text`">
                {{ year.employee_name }}: {{ year.service }}
                <div v-if="year.diagnostic && companyType === 'medical'">Diagnostic: {{ year.diagnostic }}</div>
              </h2>
              <div>
                {{ year.description }}
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>
    </b-modal>
    <b-modal id="theServices" no-fade="no-fade" :title="$t('calendar.select_service')" scrollable hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0">
        <input type="text" class="form-control round mb-4" id="service-search" :placeholder="$t('calendar.search_service')" v-model="searcheS" @input="filteredListService">
      </div>
      <b-row>
        <b-form-group class="col-sm-12 employeeDetails" v-for="(emp, id) in optionsServices" :key="id" v-model="emp.id" :ref="'services_'+emp.id">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setService(emp)">
            <b-row no-gutters>
              <b-col md="2">
                <b-card-img :src="emp.profile_image || require('../../../assets/images/page-img/08.jpg')" style="height: 50px; width: auto;"></b-card-img>
              </b-col>
              <b-col md="1"></b-col>
              <b-col md="9">
                <div class="employeeName">{{ emp.name }}</div>
                <div class="serviceDescription">{{ emp.description }}</div>
                <div class="serviceDuration">{{ $t('calendar.duration') }}: {{ emp.duration }} {{ emp.duration_type }}</div>
                <div class="servicePrice">{{ $t('calendar.price') }}: {{ emp.price }} {{ priceCurrency }}</div>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </b-row>
    </b-modal>
    <b-modal id="theEmployee" no-fade="no-fade" :title="$t('calendar.select_employee')" scrollable hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0">
        <input type="text" autocomplete="off" class="form-control round mb-4" id="employee-search" :placeholder="$t('calendar.search_employee')" v-model="searche" @input="filteredListEmployee">
      </div>
      <b-row>
        <b-form-group class="col-sm-12 employeeDetails d-none" :ref="'employee_00000'">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setEmployee('first')">
            <b-row no-gutters>
              <b-col md="2" >
                <i class="ri-user-shared-line" style="color: black; font-size: 20px;"></i>
              </b-col>
              <b-col md="1"></b-col>
              <b-col md="9">
                <div class="employeeName">
                {{ $t('calendar.first_with_availability') }}
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
        <b-form-group class="col-sm-12 employeeDetails" v-for="(emp, id) in companyEmployees" :key="id" v-model="emp.id" :ref="'employee_'+emp.id">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setEmployee(emp)">
            <b-row no-gutters>
              <b-col md="2">
                <b-card-img :src="emp.logo || require('../../../assets/images/page-img/08.jpg')" style="height: 50px; width: auto;"></b-card-img>
              </b-col>
              <b-col md="1"></b-col>
              <b-col md="9">
                <div class="employeeName">{{ emp.name }}</div>
                {{ $t('calendar.bookings_for_that_date') }}: {{ emp.noOfBookings }}
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </b-row>
    </b-modal>
    <b-modal id="theDiscount" no-fade="no-fade" :title="$t('extra.add_discount')" scrollable hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0">
        <input type="text" autocomplete="off" class="form-control round mb-4" id="discount-search" :placeholder="$t('extra.search_discount')" v-model="searchd" @input="filteredListDiscount">
      </div>
      <b-row>
        <b-form-group class="col-sm-12 employeeDetails" v-for="(emp, id) in companyDiscounts" :key="id" v-model="emp.id" :ref="'discount_'+emp.id">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setDiscount(emp)">
            <b-row no-gutters>
              <b-col md="2">
                <b-card-img :src="require('../../../assets/images/page-img/30.png')" style="height: 50px; width: auto;"></b-card-img>
              </b-col>
              <b-col md="1"></b-col>
              <b-col md="9">
                <div class="employeeName">{{ emp.name }}</div>
                {{ emp.value }} {{ emp.value_type === '%' ? emp.value_type : priceCurrency }}
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </b-row>
    </b-modal>
    <b-modal id="message" no-fade="no-fade" title="Send message" hide-footer static centered no-close-on-backdrop>
      <div class="row">
        <label class="col-sm-2 col-form-label">{{ $t('calendar.to_user') }}:</label>
        <div class="col-sm-10">
          <input v-model="emailTo" type="text" class="form-control" readonly>
        </div>
        <label class="col-sm-2 col-form-label">{{ $t('calendar.message') }}:</label>
        <div class="col-sm-10 mb-sm-1">
          <textarea v-model="emailText" class="textarea form-control" rows="5" :placeholder="$t('calendar.add_message')"></textarea>
        </div>
        <div class="col-sm-12 text-right">
          <div class="send-btn">
            <button type="submit" class="btn btn-primary" v-on:click="sendMessageTo()">{{ $t('calendar.send') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="meet" no-fade="no-fade" size="xl" static hide-footer centered @close="closeMeet">
      <template #modal-title="">
        <b-button size="sm" variant="warning" @click="showEventModal"><i class="fas fa-calendar-check"></i> {{ $t('feature.booking') }}</b-button>
        <b-button size="sm" variant="warning" @click="showHolidayModal" class="ml-1"><i class="fas fa-bell-slash"></i> {{ $t('feature.timeoff') }}</b-button>
        <b-button size="sm" class="float-right ml-1" variant="success" @click="showMeeting(eventId)"><i class="fas fa-video"></i> {{ $t('feature.videocall') }}</b-button>
      </template>
      <div class="row">
        <video-conference v-if="meetingId" :theId="meetingId"></video-conference>
      </div>
    </b-modal>
    <b-modal class="theModal" no-fade="no-fade" id="modal" ref="modal" v-model="modal" size="xl" static hide-footer centered >
      <div class="isLoadingEvent" v-if="isLoadingEvent"><div class="loader"></div></div>
      <template #modal-title="">
        <b-button size="sm" variant="success" ><i class="fas fa-calendar-check"></i> {{ $t('feature.booking') }}</b-button>
        <b-button size="sm" variant="warning" @click="showHolidayModal" class="ml-1"><i class="fas fa-bell-slash"></i> {{ $t('feature.timeoff') }}</b-button>
        <b-button size="sm" class="float-right ml-1" variant="primary" v-if="hasReminder" @click="addReminder"> <i class="fas fa-solid fa-bell"></i> Reminder active</b-button>
        <b-button size="sm" class="float-right ml-1" variant="danger" v-if="$refs.eventID" @click="showMeeting($refs.eventID)"> <i class="fas fa-video"></i>{{ $t('feature.videocall') }}</b-button>

      </template>
      <input type="hidden" ref="eventID" />
      <div class="row">
        <div class="col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <b>{{ $t('calendar.starting_date') }}:</b> <b-form-input v-model="startingDate" type="datetime-local" value="2019-12-19T13:45:00" @change="setEndingDateAndTotalPrice"></b-form-input>
            </div>
            <div class="col-sm-6">
              <b>{{ $t('calendar.ending_date') }}:</b> <b-form-input v-model="endingDate" type="datetime-local"  ></b-form-input>
            </div>
          </div>
        </div>
        <div class="col-sm-6 row m-0 p-0">
          <div class="col-sm-12 pb-0">
            <b>{{ $t('calendar.services') }}:</b>
            <b-button variant="success" @click="changeServices" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;"><i class="fab fa-creative-commons-nd"></i> {{ $t('calendar.add_service') }}</b-button>
          </div>
          <div class="col-sm-12 pt-0" >
            <div v-for="(emp, id) in activeServices" :key="id" class="activeServices">
              {{ emp.name }}({{ emp.duration }} {{ emp.duration_type}}) <span style="color:#27b345">{{ emp.price }} {{ priceCurrency }}</span>
              <span @click="removeService(emp)"><i class="ri-close-circle-fill" style="color: red; cursor: pointer"></i></span>
            </div>
          </div>
        </div>
        <div class="col-sm-12 m-0 p-0">
          <hr>
        </div>
        <div class="col-sm-3 row m-0 p-0">
          <div class="col-12 pb-0" style="height: 20px;"><b>{{ $t('calendar.realised_by') }}:</b></div>
          <div class="col-12 pt-0" style="font-size: 11px;">
            <img :src="employee_image" ref="employeeLogo" class="employee_logo_new"/>
            <b-button variant="success" ref="employeeName" @click="changeEmployee" class="mb-1 mr-1 change btn-sm" style="font-size: 11px;">
              <i class="ri-bill-fill"></i>Change</b-button>
            <br>
            <input type="hidden" ref="employeeToken">
            <div v-show="isVisible">
              <i class="fas fa-envelope"></i> <span ref="employeeEmail" class="send_message" v-on:click="sendMessageToEmployee()"></span><br>
              <span v-if="canSeePhone"><i class="fas fa-phone-square"></i> <a ref="employeePhone" ></a><br></span>
              {{ $t('calendar.bookings_for_that_date') }}: <a ref="employeeBooking">0</a>
            </div>
          </div>
         </div>
        <div class="col-sm-3 row m-0 p-0" >
          <div class="col-12"><b>{{ $t('calendar.customer') }}:</b></div>
          <div class="col-12 pt-0" style="font-size: 11px;">
            <img :src="customer_image" ref="customer_logo" class="employee_logo_new" />
            <input type="hidden" ref="customerToken">
            <input type="hidden" ref="customerCNP">
            <span ref="customerNew"></span>
            <b-button variant="success" ref="customerName" @click="editCustomer" class="mb-1 mr-1 change btn-sm" style="font-size: 11px;"></b-button>
            <span v-show="customerTokenActive" ref="editCustomer" class="addDiscount" @click="changeCustomers">{{ $t('customer_.change') }}</span>
            <div v-show="isVisible">
              <span ref="customerFavorit" class="customerFavorit" @click="setFavorite"><i class="ri-star-line"></i></span>
              <i class="fas fa-envelope"></i> <span ref="customerEmail" class="send_message" v-on:click="sendMessageToCustomer()"></span><br>
              <span v-if="canSeePhone"><i class="fas fa-phone-square"></i> <a ref="customerPhone" ></a><br></span>

              <div style="display: none;">
              {{ $t('calendar.no_of_bookings') }}: <span ref="customerBookings"></span><br>
              {{ $t('calendar.total_payed') }}: <span ref="customerMoneySpent"></span> {{ this.priceCurrency }}
              </div>
            </div>
            <br><br>
            <b-button v-show="customerTokenActive & companyType === 'medical'" variant="success" @click="editPatient" class="mb-1 mr-1 change btn-sm" style="font-size: 11px;">{{ $t('customer_.fisa') }}</b-button>
            <b-button v-show="customerTokenActive & companyType === 'medical'" variant="success" @click="editCustomerHistory" class="mb-1 mr-1 change btn-sm" style="font-size: 11px;">{{ $t('customer_.history') }}</b-button>
          </div>
        </div>
        <div class="col-sm-6 mt-0 pt-0">
          <b>{{ $t('extra.subtotal') }}:</b> <span ref="eventPriceSubtotal" class="eventPriceSubtotal font-weight-bold" style="color:#27b345"></span>
          <br>
          <b>{{ $t('extra.discount') }}:</b> <span ref="eventPriceDiscount" class="font-weight-bold" style="color:#27b345"></span>
          <span v-if="!addDiscountValue" @click="addDiscount" class="addDiscount">{{ $t('extra.add_discount') }}</span><br>
          <span ref="eventPriceDiscountToken" class="eventPriceDiscountToken"></span>
          <span v-if="addDiscountValue" @click="removeDiscount" class="addDiscount">{{ $t('extra.remove_discount') }}</span><br>
          <span class="eventPriceTotal"><b>{{ $t('calendar.total_price') }}:</b> <span ref="eventPrice" class="font-weight-bold" style="color:#27b345"></span></span>
          <br>
          <span style="display: none;"><b>{{ $t('calendar.total_payed') }}:</b> <span ref="eventPricePayed" class="font-weight-bold" style="color:#27b345"></span><br></span>
          <b>{{ $t('calendar.payment_status') }}:</b> <span ref="eventPaymentStatus" class="font-weight-bold" style="color:#27b345"></span><br>
          <b>{{ $t('calendar.payment_status_type') }}:</b> <span ref="eventPaymentSelected" class="font-weight-bold" style="color:#27b345"></span><br>
          <div class="haspayed" ><b>{{ $t('calendar.payed_with') }}:</b> &nbsp;&nbsp;&nbsp;&nbsp;
            <label><input type="radio" name="thepayment" v-model="thepayment" value="cash" @click="setPayment('cash')"/> {{ $t('calendar.cash') }}</label>
            <label><input type="radio" name="thepayment" v-model="thepayment" value="card" @click="setPayment('card')"/> {{ $t('calendar.card') }}</label>
            <label><input type="radio" name="thepayment" v-model="thepayment" value="absent" @click="setPayment('absent')"/> {{ $t('calendar.absent') }}</label>
            <input type="hidden" ref="haspayed">
          </div>
        </div>
        <div class="col-sm-12 m-0 p-0">
          <hr>
        </div>
        <div class="col-sm-12 row p-0 m-0">
          <div class="col-sm-6 text-left">
            <b>{{ $t('feature.observations') }}:</b>
            <b-form-textarea class="description" :placeholder="$t('feature.observations')" v-model="descriptionEvent"></b-form-textarea>
          </div>
          <div class="col-sm-6 text-right">
            <span v-show="isVisible">
              <span class="addedBy">{{ $t('extra.added_by') }} <span ref="addedBy"></span></span>: <span class="addedBy" ref="createdAt"></span>
              <span class="addedBy" style="display: block" ref="tokenId"></span><br>
            </span>
            <b-button variant="primary" style="font-size:12px;" class="align-text-bottom mb-3 mr-1 btn-sm " @click="saveEvent()"><i class="fas fa-save"></i>{{ $t('calendar.save_booking') }}</b-button>
            <b-button variant="danger" v-if="$refs.eventID && $refs.eventID.value !== ''" style="font-size:12px;" class="align-text-bottom  mb-3 mr-1 btn-sm" @click="openDelete()"><i class="fas fa-ban"></i>{{ $t('calendar.cancel_booking') }}</b-button>
            <b-button variant="success" v-if="$refs.eventID && $refs.eventID.value !== ''" style="font-size:12px;" class="align-text-bottom  mb-3 mr-1 btn-sm" @click="showHistory()"><i class="fas fa-history"></i> Show history</b-button>
            <b-button variant="warning" v-if="$refs.eventID && $refs.eventID.value !== ''" style="font-size:12px;" class="align-text-bottom  mb-3 mr-1 btn-sm" @click="addReminder()"><i class="fas fa-bell"></i> Reminder</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="holiday" no-fade="no-fade" size="xl" static hide-footer centered>
      <template #modal-title="">
        <b-button size="sm" variant="warning" @click="showEventModal"><i class="fas fa-calendar-check"></i> {{ $t('feature.booking') }}</b-button>
        <b-button size="sm" variant="success" class="ml-1"><i class="fas fa-bell-slash"></i> {{ $t('feature.timeoff') }}</b-button>
        <b-button size="sm" class="float-right ml-1" v-if="$refs.eventIDHoliday" variant="danger" @click="showMeeting($refs.eventIDHoliday)"><i class="fas fa-video"></i> {{ $t('feature.videocall') }}</b-button>

      </template>
      <div class="row">
        <input type="hidden" ref="eventIDHoliday" />
        <input type="hidden" ref="employeeTokenHoliday">
        <div class="col-sm-6">
          {{ $t('calendar.starting_date') }}: <b-form-input v-model="startingDateHoliday" type="datetime-local" value="2019-12-19T13:45:00"></b-form-input>
          {{ $t('calendar.ending_date') }}: <b-form-input v-model="endingDateHoliday" type="datetime-local"  ></b-form-input>
        </div>
        <div class="col-sm-6 row">
          <div class="col-4">
            {{ $t('calendar.employee') }}:<br>
            <img src="" ref="employeeLogoHoliday" class="employee_logo"/>
          </div>
          <div class="col-8">
            <b-button pill variant="success" ref="employeeNameHoliday" @click="changeEmployeeHoliday" class="mb-1 mr-1 change"><i class="ri-bill-fill"></i>Change</b-button>
            <br>
            <input type="hidden" ref="employeeTokenHoliday">
            <i v-show="$refs.employeeEmailHoliday" class="fas fa-envelope"></i> <span ref="employeeEmailHoliday" class="send_message" v-on:click="sendMessageToEmployee()"></span><br>
            <span v-if="canSeePhone"><i class="fas fa-phone-square"></i> <a ref="employeePhoneHoliday" ></a><br></span>
          </div>
        </div>
        <div class="col-sm-12">
          {{ $t('feature.description') }}:
          <b-form-input type="text" :placeholder="$t('feature.description')" v-model="descriptionHoliday"></b-form-input>
        </div>
        <div class="col-sm-12 text-right">
          <br>
          <b-button variant="primary" class="mr-1" @click="saveEventHoliday()"><i class="ri-heart-fill"></i>{{ $t('feature.save_holiday') }}</b-button>
          <b-button variant="danger" @click="deleteEvent">
            <i class="ri-radio-button-fill"></i>
            {{ $t('feature.delete_holiday') }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal id="patient" no-fade="no-fade" size="xl" static hide-footer centered>
      <template #modal-title="">
        <b-button size="sm" :variant="record === 'current_record' ? 'success' : 'warning'" @click="record = 'current_record'; editPatient()">{{ $t('customer_.current_record') }}</b-button>
        <b-button size="sm" :variant="record === 'history_record' ? 'success' : 'warning'" @click="record = 'history_record'; getHistoryRecord()" class="ml-1">{{ $t('customer_.history_record') }}</b-button>
        <b-button size="sm" :variant="record === 'template_record' ? 'success' : 'warning'" @click="record = 'template_record'; templatesDisplay()" class="ml-1">{{ $t('customer_.templates') }}</b-button>
      </template>
      <div class="" v-if="record === 'current_record'">
        <div class="row">
          <div class="col-sm-12 diagnostic">
            {{ $t('customer_.select_template') }} <br>
            <b-form-select
              v-model="selectedItemTemplate"
              :options="itemsPatient"
              value-field="token"
              text-field="name"
              @change="selectTemplate"
            >
            </b-form-select>
            <br><br>
            {{ $t('customer_.select_diagnostic') }} <br>
            <v-combobox
              v-model="chips"
              :items="someitems"
              chips
              clearable
              :label="$t('customer_.select_diagnostic')"
              multiple
              prepend-icon="mdi-filter-variant"
              solo
              @input="addDiagnosis"
              v-show="templateSelected"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong>&nbsp;
                </v-chip>
              </template>
            </v-combobox><br>
            <div v-show="templateSelected">
              <vue-editor v-model="selectedItemTemplateData" id="editor_record_new"></vue-editor>
              <br>
              <b-button size="sm" variant="success" class="ml-1" @click="saveRecord">{{ $t('customer_.save_record') }}</b-button>
              <b-button size="sm" variant="warning" class="ml-1" @click="printRecord">{{ $t('customer_.print') }}</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="record === 'history_record'">
        <div class="row">
          <div class="col-sm-3">
            <v-list-item-group v-model="selectedItemRecord" color="primary" >
              <v-list-item v-for="(item, i) in itemsPatientRecords" :key="i" >
                <v-list-item-content @click="readCurrentRecord(item)">
                  <i class="ri-file-list-fill" style="display: contents"></i> {{ item.updated_at }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </div>
          <div class="col-sm-9">
            <vue-editor disabled="true" v-model="recordData" v-show="recordId !== ''" id="editor_record"></vue-editor>
            <br>
            <b-button size="sm" variant="warning" class="ml-1" v-show="recordId !== ''" @click="printRecord1">{{ $t('customer_.print') }}</b-button>
          </div>
        </div>
      </div>
      <div class="" v-if="record === 'template_record'">
        <div class="row">
          <v-list dense class="col-sm-3">
            <b-button size="sm" variant="success" class="ml-1" @click="addNewTemplate">{{ $t('customer_.add_template') }}</b-button>
            <v-list-item-group v-model="selectedItem" color="primary" >
              <v-list-item v-for="(item, i) in itemsPatient" :key="i" >
                <v-list-item-content @click="setCurrentTemplate(item)">
                  <i class="ri-file-list-fill" style="display: contents"></i> {{ item.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div class="col-sm-9">
            {{ $t('customer_.template_name') }}
            <input v-model="templateName" type="text" autocomplete="off" class="form-control round" :placeholder="$t('customer_.template_name')" />
            <br>
            {{ $t('customer_.template_content') }}
            <vue-editor v-model="templateData" id="editor_sablon"></vue-editor>
            <br>
            <input type="hidden" v-model="templateId" />
            <b-button size="sm" variant="success" class="ml-1" @click="saveTemplate">{{ $t('customer_.save_template') }}</b-button>
            <b-button size="sm" variant="danger" class="ml-1" v-show="templateId !== ''" @click="deleteTemplate">{{ $t('customer_.delete_template') }}</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="searchBooking" no-fade="no-fade" size="xl" static hide-footer centered>
      <template #modal-title="">Cauta booking dupa id</template>
      <div>
        <div class="row">
          <b-form-input v-model="searchTheBooking" placeholder="Introdu id booking" autocomplete="off" class="col-9"></b-form-input>
          <b-button size="sm" variant="success" @click="searchTheBookingMethod" class="col-3"><i class="fas fa-calendar"></i> Search Booking</b-button>
        </div>
        <div class="progress_class" v-show="isProgress">
          Rezultatele cautarii tale se incarca
        </div>
        <v-progress-linear color="primary" v-show="isProgress" class="progress_class mt-10" indeterminate :height="12"></v-progress-linear>
        <div class="search-results-none mt-10" v-show="searchText.length > 0">{{ searchText }}</div>
        <div class="searchresults mt-10 p-2" v-show="searchResults.id" @click="openSearchResult">
          Am gasit 1 rezultat: <b>{{ searchResults.id }}</b>
        </div>
      </div>
    </b-modal>
    <b-modal id="bookingHistory" no-fade="no-fade" size="xl" static hide-footer centered>
      <template #modal-title="">Booking history</template>
      <div>
        <div v-for="(item, key) in bookingHistory" :key="key" style="padding: 10px 0px; border-bottom: 1px solid #378de0;">
          <div v-if="item.type === '1'"><b>{{ item.thedate }}</b>: Booking created by {{ item.fname }} {{ item.lname }}</div>
          <div v-if="item.type === '2'"><b>{{ item.thedate }}</b>: Booking changed by {{ item.fname }} {{ item.lname }}</div>
          <div v-if="item.type === '3'"><b>{{ item.thedate }}</b>: Booking deleted by {{ item.fname }} {{ item.lname }}</div>
        </div>
      </div>
    </b-modal>
    <b-modal id="reminder" no-fade="no-fade" title="Reminder" hide-footer static centered no-close-on-backdrop>
      <div class="row">
        <div class="col-sm-12">
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(120)">2 hours before</b-button>
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(60)">1 hour before</b-button>
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(30)">30 minutes before</b-button>
          <b-button variant="success" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;" @click="setReminderTo(10)">10 minutes before</b-button>
          <b-form-input v-model="startingDateReminder" type="datetime-local" :max="startingDateReminderMin" :min="computedMaxDate" ></b-form-input>
        </div>
        <div class="col-sm-12 mb-sm-1">
          <textarea v-model="reminderText" class="textarea form-control" rows="2" :placeholder="$t('calendar.add_message')"></textarea>
        </div>
        <div class="col-sm-12 text-right">
          <div class="send-btn">
            <b-button variant="primary" style="font-size:12px;" class="align-text-bottom mb-3 mr-1 btn-sm " @click="saveReminder()"><i class="fas fa-save"></i>Save</b-button>
            <b-button variant="danger" style="font-size:12px;" class="align-text-bottom  mb-3 mr-1 btn-sm" @click="deleteReminder()"><i class="fas fa-ban"></i>Delete</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <v-row class="fill-height" id="scrollTopOnMobile" >
      <div class="isLoading" v-if="isLoading"><div class="loader"></div></div>
      <v-col>
        <div class="row m-0" v-show="theFilters">
          <div class="col-12">
            <v-combobox
              v-model="selectedEmployees"
              :items="categoriesSelected"
              item-text="name"
              item-value="token"
              label="Select who will be displayed"
              multiple
              chips
              clearable
              clear-icon="x"
              @change="updateCalendarEmployees"
            >
            </v-combobox>
          </div>
          <div class="col-12 col-sm-3 col-lg-2 text-center px-0">
              Slot
              <b-form-select v-model="slotSelect" :options="theSlots" @change="changeSlot" class="ml-1 mr-1" style="width:100px">
              </b-form-select>
            </div>
          <div class="col-12 col-sm-9 col-lg-4 text-center px-0">
              <v-btn
                outlined
                class="mr-1"
                :class="(isWorking ? 'isWorking' : '')"
                color="grey darken-2"
                @click="setWorking"
              >
                {{ $t('calendar.show_working') }}
              </v-btn>

              <v-btn
                outlined
                class="mr-1"
                :class="(isWorkingDays ? 'isWorking' : '')"
                color="grey darken-2"
                @click="setWorkingDays"
              >
                {{ $t('calendar.show_working_days') }}
              </v-btn>
            </div>
          <div class="col-12 col-sm-12 col-lg-3 text-center px-0">
            <v-btn outlined  color="grey darken-2" :class="(type === 'category' ? 'isWorking' : '')" @click="setCalendarType('category')">{{ $t('calendar.day') }}</v-btn>
            <v-btn outlined  color="grey darken-2" :class="(type === 'week' ? 'isWorking' : '')" @click="setCalendarType('week')">{{ $t('calendar.week') }}</v-btn>
            <v-btn outlined  color="grey darken-2" :class="(type === 'month' ? 'isWorking' : '')" @click="setCalendarType('month')">{{ $t('calendar.month') }}</v-btn>
          </div>
          <div class="col-12 col-sm-3 col-lg-1 text-center px-0" id="scrollOnMobile">
            <v-btn
              outlined
              class="ml-4 mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $t('calendar.today') }}
            </v-btn>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-12 col-sm-3"><b-button size="sm" variant="success" @click="openFilters"><i class="fas fa-filter"></i> Filters</b-button></div>
          <div class="col-12 col-sm-6 text-center ">
            <b-button size="sm" variant="success" @click="prev">
              <i class="fa fa-chevron-left" />
            </b-button>
            <div v-if="$refs.calendar" style="display: inline" class="calendar-time">
              {{ calendarName }}
            </div>
            <b-button size="sm" variant="success" @click="next">
              <i class="fa fa-chevron-right"/>
            </b-button>
          </div>
          <div class="col-sm-3 text-right searchBookingMobile"><b-button size="sm" variant="success" @click="searchBookings"><i class="fas fa-calendar-check"></i> Search booking</b-button></div>
        </div>
        <v-sheet class="theCalendar">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :event-ripple="false"
            :type="type"
            :categories="categories"
            category-show-all
            :weekdays="weekdays"
            @click:event="showEvent"
            @touchend:event="showEvent"
            @touchstart:event="setTheTimeout"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:time="openNewEvent"
            @click:time-category="openNewEventCategory"
            @change="updateRange"
            @mousedown:event="startDrag"
            @touchmove:day-category="startDrag"
            @mousedown:time="startTime"
            @mousedown:day="startTime"
            @mousemove:time="mouseMove"
            @mousemove:day="mouseMove"
            @mousemove:day-category="mouseMoveCategory"
            @mouseup:time="endDrag"
            @mouseup:day="endDrag"
            @mouseleave.native="cancelDrag"
            @mouseleave:time="mouseExit"
            @mouseenter:time-category="setCategory"
            @mouseenter:time="bla"
            :interval-format="intervalFormat"
            :interval-height = "3 * this.slotSelect"
            :first-interval = "intervalFirst1"
            :interval-minutes = "intervalMinutes1"
            :interval-count = "intervalCount1"
          >
            <template v-slot:day-body="{ date, week }">
              <div class="v-current-time" id="v-current-time-id"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"></div>
            </template>
            <template v-slot:category="{ category }">
              <div class="text-center">
                <img :src="companyEmployeesPictures[category]" class="img-fluid rounded mr-3" style="height:100px; width:100px; margin-left: auto !important; margin-right: auto !important; object-fit: cover;"/>
                <h4 class="theCategoryClass">{{ category }}</h4>
              </div>
            </template>
            <template v-slot:interval="{ date, hour, minute, weekday, category }">
              <div v-if="type === 'week' && breaks[weekday] && breaks[weekday].start.startsWith(hour + ':' + minute)" class="breakperiod"
              :style="'height: ' + ( breaks[weekday].duration * 60 / slotSelect * 30) + 'px'" >{{ $t('calendar.break') }}</div>

              <div v-if="type === 'category' && employeeBreaks[category][weekday] && employeeBreaks[category][weekday].start.startsWith(hour + ':' + minute)"
                   class="breakperiod"
                   :style="'height: ' + ( employeeBreaks[category][weekday].duration * 60 / slotSelect * 30) + 'px'"
              >
                <span>{{ $t('calendar.break') }}</span>
              </div>
            </template>
            <template v-slot:event="{ event, eventParsed, timed, timeSummary}">
              <div class="v-event-draggable">
                <div class="eventTime">{{ timeSummary() }}</div>
                <div class="eventStatus">
                  <i class="ri-currency-line" v-if="event.payment_final_status === 'cash'"></i>
                  <i class="ri-bank-card-2-line" v-if="event.payment_final_status === 'card'"></i>
                  <i class="ri-thumb-down-fill" v-if="event.payment_final_status === 'absent'"></i>
                  <i class="ri-home-2-fill" v-if="event.holiday === '1'"></i>
                </div>

                <div class="eventCustomer" v-if="event.holiday === '0'">
                  <span v-if="event.customer_favorit !== '0'"><i class="ri-star-fill"></i></span>
                  <span v-if="event.customer_new !== '0'"><i class="ri-user-add-line"></i></span>
                  {{ event.customer_name }}
                </div>
                <div class="eventService" v-if="event.service && event.holiday === '0'">
                  <div v-for="(item, id) in event.service" :key="id">
                    {{ item.name }}
                  </div>
                </div>

                <div class="eventCustomer" v-if="event.holiday === '1'">
                  {{ event.employee_fname }} {{ event.employee_lname }}
                </div>
                <div class="eventService" v-if="event.holiday === '1'">{{ event.description }}</div>

              </div>
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <component :is="`style`">
      @media(min-width:979px) {
      .v-calendar.v-calendar-category .v-calendar-daily__head,
      .v-calendar.v-calendar-category .v-calendar-daily__body{
      width: {{ calendarSizeDesktop }}
      }
      }
      @media(max-width:979px) {
        .v-calendar.v-calendar-category .v-calendar-daily__head,
        .v-calendar.v-calendar-category .v-calendar-daily__body{
      width: {{ calendarSizeTablet }}
        }
      }
      @media(max-width:767px) {
      .v-calendar.v-calendar-category .v-calendar-daily__head,
      .v-calendar.v-calendar-category .v-calendar-daily__body{
      width: {{ calendarSizeMobile }}
      }
      }
    </component>
  </v-app>
</template>

<script>
import { core } from '@/config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import { VueEditor } from 'vue2-editor'
import VideoConference from '@/views/Company/VideoCall'

export default {
  components: {
    VideoConference,
    VueEditor
  },
  data: () => ({
    hasReminder: false,
    employee_image: require('../../../assets/images/user/employee_placeholder.png'),
    customer_image: require('../../../assets/images/user/customer_placeholder.png'),
    uploadStarted: false,
    fileClicked: '',
    files: {
      html: 'ri-html5-line',
      pdf: 'ri-file-pdf-line',
      png: 'ri-image-fill',
      jpg: 'ri-image-fill',
      gif: 'ri-image-fill',
      txt: 'ri-file-text-line',
      doc: 'ri-file-text-line',
      docs: 'ri-file-text-line',
      xls: 'ri-file-excel-2-line',
      zip: 'ri-folder-zip-fill'
    },
    tree: [],
    items: [],
    years: [],
    editor1Content: '',
    theVariant: 'details',
    theStorageBase: API.API_LIST,
    theTimeout: false,
    theCategory: '',
    // EVENT MODAL
    setDraged: false,
    hourTooltip: false,
    isLoading: false,
    isLoadingEvent: false,
    addCustomerName: '',
    addCustomerNameEdit: '',
    addCustomerLastName: '',
    addCustomerLastNameEdit: '',
    addCustomerCNP: '',
    addCustomerCNPEdit: '',
    addCustomerPhone: '',
    addCustomerPhoneEdit: '',
    addCustomerEmail: '',
    addCustomerPassword: '12booky',
    addCustomerEmailEdit: '',
    addCustomerPasswordEdit: '',
    addCustomerToken: '',
    selectedOnHoldToken: null,
    onHoldEvents: [],
    thepayment: '',
    priceCurrency: '',
    searchOH: '',
    searche: '',
    searcheS: '',
    searcheC: '',
    searchd: '',
    theEmployee: [],
    message: [],
    emailTo: '',
    emailText: '',
    startingDate: '',
    startingDateReminder: '',
    startingDateReminderMin: '',
    reminderText: '',
    endingDate: '',
    startingDateHoliday: '',
    endingDateHoliday: '',
    descriptionHoliday: '',
    descriptionEvent: '',
    modal: false,
    optionsServices: [],
    activeServices: [],
    search: '',
    companyEmployees: [],
    companyEmployeesPictures: [],
    companyActiveEmployee: [],
    companyServices: [],
    companyCustomers: [],
    optionsCustomers: [],
    globalTimeout: null,
    apiToken: localStorage.getItem('api_token') || '',
    companyToken: localStorage.getItem('company_token') || '',
    // CALENDAR OPTIONS
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    weekdaysInterval: [],
    value: '',
    ready: false,
    focus: '',
    type: 'day',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['#19bdb4'],
    categories: [],
    categoriesSelected: [],
    selectedEmployees: [],
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    dragStartDate: null,
    dragEndDate: null,
    theStart: null,
    theEnd: null,
    canEditEvents: true,
    canSendMessage: true,
    canSeePhone: true,
    addPast: true,
    firstInterval: 0,
    intervalCount: 24,
    isWorking: false,
    isWorkingDays: false,
    currentCategory: false,
    breaks: [],
    employeeBreaks: [],
    slotSelect: 60,
    theSlots: [
      { value: 10, text: '10 Min' },
      { value: 15, text: '15 Min' },
      { value: 30, text: '30 Min' },
      { value: 60, text: '1 H' }
    ],
    calendarSizeDesktop: '100%',
    calendarSizeTablet: '100%',
    calendarSizeMobile: '100%',
    enteredCategory: '',
    isHoliday: false,
    startingCounter: 0,
    interval: 0,
    addDiscountValue: false,
    selectedDiscountValue: 0,
    companyDiscounts: [],
    voucherSelect: '',
    isDraggingEvent: false,
    user: {
      logo: require('../../../assets/images/user/customer_placeholder.png')
    },
    newuser: {
      logo: require('../../../assets/images/user/customer_placeholder.png')
    },
    isVisible: false,
    customerTokenActive: false,
    companyType: '',
    record: '',
    selectedItem: '',
    selectedItemRecord: '',
    selectedItemTemplate: '',
    selectedItemTemplateData: '',
    templateSelected: '',
    recordData: '',
    recordId: '',
    itemsPatient: [],
    itemsPatientRecords: [],
    templateId: '',
    templateName: '',
    templateData: '',
    chips: [],
    someitems: [],
    meetingId: null,
    intervalFirst1: 0,
    intervalMinutes1: 0,
    intervalCount1: 0,
    calendarName: '',
    searchTheBooking: '',
    searchText: '',
    searchResults: [],
    isProgress: false,
    timer: null,
    theFilters: null,
    bookingHistory: []
  }),
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    nowTime () {
      const cal = this.$refs.calendar
      if (!cal && !this.isMounted) {
        return -1
      }

      return cal.formatTime(cal.times.now)
    },
    computedMaxDate () {
      const maxDate = new Date()
      return maxDate.toISOString().slice(0, 16)
    }
  },
  mounted () {
    this.getLocaleByLang()
    this.updateCalendarName()
    document.addEventListener('mousemove', this.mouseIsMoving)
    this.isLoading = true
    core.index()
    this.getCompanyEmployees()
    this.getUserRights()
    this.getCompanyDetails()
    const thisOne = this
    setTimeout(() => {
      thisOne.getCalendarSettings()
      thisOne.updateTime()
    }, 5000)
    const scrollToMenu = document.getElementById('scrollOnMobile')
    scrollToMenu.scrollIntoView()
    setTimeout(() => {
      thisOne.scrollToTime()
    }, 4000)
  },
  methods: {
    saveReminder () {
      const postData = {
        theDate: this.startingDateReminder,
        message: this.reminderText,
        booking: this.$refs.eventID.value
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_REMINDER, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.hasReminder = true
            core.showSnackbar('success', 'Reminder adaugat cu succes!')
            this.$bvModal.hide('reminder')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    deleteReminder () {
      const postData = {
        booking: this.$refs.eventID.value
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_DELETE_REMINDER, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.startingDateReminder = ''
            this.startingDateReminderMin = ''
            this.reminderText = ''
            this.hasReminder = false
            core.showSnackbar('success', 'Reminder sters cu succes!')
            this.$bvModal.hide('reminder')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setReminderTo (minutes) {
      const theDate = new Date(this.startingDate)
      theDate.setMinutes(theDate.getMinutes() - minutes)
      this.startingDateReminder = theDate.toISOString().slice(0, 16)
    },
    addReminder () {
      this.$bvModal.show('reminder')
    },
    showHistory () {
      const postData = {
        booking: this.$refs.eventID.value
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_HISTORY_BOOKINGS, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.bookingHistory = response.data.history
            this.$bvModal.show('bookingHistory')
          }
        })
        .catch((error) => {
          console.error(error.response)
          this.isProgress = false
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    searchTheBookingMethod () {
      if (this.searchTheBooking.length >= 3) {
        this.searchText = ''
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.searchBookingByFilters(this.searchTheBooking)
        }, 800)
      } else {
        this.searchText = 'Adauga cel putin 3 caractere'
      }
    },
    searchBookingByFilters (theFilters) {
      this.isProgress = true
      this.searchText = ''
      this.searchResults = []
      const postData = {
        booking: theFilters
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SEARCH_BOOKINGS, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.isProgress = false
            if (response.data.booking.length === 0) {
              this.searchText = 'Nu s-au gasit rezultate'
            } else {
              this.searchResults = response.data.booking[0]
            }
          }
        })
        .catch((error) => {
          console.error(error.response)
          this.isProgress = false
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    searchBookings () {
      this.searchText = ''
      this.$bvModal.show('searchBooking')
    },
    openFilters () {
      this.theFilters = !this.theFilters
      this.$vuetify.goTo(0)
    },
    updateCalendarEmployees (value) {
      if (value.length > 0) {
        this.categories = value
      } else {
        this.categories = this.categoriesSelected
      }
    },
    addDiagnosis () {
      const postData = {
        booking: this.$refs.eventID.value,
        company: this.companyToken,
        diagnosis: JSON.stringify(this.chips)
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_PATIENT_DIAGNOSIS, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            // update diagnostic in patient record
            let savedChips = ''
            for (let x = 0; x < this.chips.length; x++) {
              savedChips += ', ' + this.chips[x]
            }
            this.selectedItemTemplateData = this.selectedItemTemplateData.replace(/Diagnostic:(.*)~/gi, 'Diagnostic: ' + savedChips.substr(2) + '~')
            core.showSnackbar('success', 'Diagnostic salvat cu succes')
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    remove (item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
      this.addDiagnosis()
    },
    getCompanyDetails () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_COMPANY_DETAILS)
        .then((response) => {
          if (response.data.status === 'success') {
            this.companyType = response.data.company.activity
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    openFileForPreview (customerToken) {
      const postData = {
        filename: this.fileClicked,
        company: this.companyToken,
        customer: customerToken
      }
      Axios.post(API.API_PREVIEW_FILE, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            window.open(response.data.path, '_blank').focus()
            this.fileClicked = []
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    deleteFile (customerToken) {
      this.$bvModal.msgBoxConfirm(this.$t('customer_.delete_file_question'))
        .then(value => {
          if (value === true) {
            const postData = {
              filename: this.fileClicked,
              company: this.companyToken,
              customer: customerToken
            }
            Axios.post(API.API_DELETE_FILE, postData)
              .then((response) => {
                if (response.data.status === 'fail') {
                  core.showSnackbar('error', response.data.message)
                } else if (response.data.status === 'success') {
                  this.items = response.data.filelist
                  this.fileClicked = []
                }
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    },
    uploadFile (customerToken) {
      const maxAllowedSize = 5 * 1024 * 1024
      if (this.$refs.file4upload.files[0].size > maxAllowedSize) {
        alert(this.$t('customer_.file_big'))
      } else {
        this.uploadStarted = true
        const formData = new FormData()
        formData.append('theFile', this.$refs.file4upload.files[0])
        formData.append('company', this.companyToken)
        formData.append('customer', customerToken)
        Axios.post(API.API_ADD_CUSTOMER_FILES, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            if (response.data.status === 'fail') {
              core.showSnackbar('error', response.data.message)
            } else if (response.data.status === 'success') {
              this.items = response.data.filelist
              this.fileClicked = []
              this.uploadStarted = false
            } else if (response.data.status === 'upgrade') {
              this.$emit('upgrade_message', response.data.nextPlan)
              this.$bvModal.show('globalUpgrade')
              this.uploadStarted = false
            }
          })
          .catch((error) => {
            this.fileClicked = []
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
            this.uploadStarted = false
          })
      }
    },
    getTheFileClicked (data) {
      this.fileClicked = data
    },
    saveObservations (customerToken) {
      const postData = {
        company: this.companyToken,
        customer: customerToken,
        observation: this.editor1Content
      }
      Axios.post(API.API_UPDATE_CUSTOMER_OBSERVATION, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer_.info_updated'))
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getObservations (customerToken) {
      const postData = {
        company: this.companyToken,
        customer: customerToken
      }
      Axios.post(API.API_GET_CUSTOMER_OBSERVATION, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.editor1Content = response.data.observation
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    displayDocuments (customerToken) {
      const postData = {
        company: this.companyToken,
        customer: customerToken
      }
      Axios.post(API.API_GET_CUSTOMER_FILES, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.items = response.data.filelist
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    displayLastBookings (customerToken) {
      const postData = {
        company: this.companyToken,
        customer: customerToken
      }
      Axios.post(API.API_GET_CUSTOMER_BOOKINGS, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.years = response.data.bookings
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setCalendarWidth () {
      if (this.companyEmployees.length > 3) {
        this.calendarSizeMobile = 'calc( ( 100% - 15px ) / 3 * ' + this.companyEmployees.length + ' );'
      }
      if (this.companyEmployees.length > 5) {
        this.calendarSizeTablet = 'calc( ( 100% - 15px ) / 5 * ' + this.companyEmployees.length + ' );'
      }
      if (this.companyEmployees.length > 10) {
        this.calendarSizeDesktop = 'calc( ( 100% - 15px ) / 10 * ' + this.companyEmployees.length + ' );'
      }
    },
    // API events
    selectOnHoldBooking (id) {
      if (this.selectedOnHoldToken === null) {
        this.selectedOnHoldToken = id
      } else {
        if (this.selectedOnHoldToken === id) {
          this.selectedOnHoldToken = null
        } else {
          this.selectedOnHoldToken = id
        }
      }
    },
    sendMessageToEmployee (email = null) {
      if (!this.canSendMessage) {
        core.showSnackbar('error', 'You don\'t have rights to send messages ')
        return
      }
      if (email === null) {
        this.emailTo = this.$refs.employeeEmail.innerHTML
      } else {
        this.emailTo = email
      }
      this.emailText = ''
      this.$bvModal.show('message')
    },
    sendMessageToCustomer (email = null) {
      if (!this.canSendMessage) {
        core.showSnackbar('error', 'You don\'t have rights to send messages ')
        return
      }
      if (email === null) {
        this.emailTo = this.$refs.customerEmail.innerHTML
      } else {
        this.emailTo = email
      }
      this.emailText = ''
      this.$bvModal.show('message')
    },
    sendMessageTo () {
      if (this.emailText === '') {
        core.showSnackbar('error', 'Message should not be empty!')
      } else {
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        const theMessage = {
          to: this.emailTo,
          message: this.emailText
        }
        Axios.post(API.API_SEND_MESSAGE, theMessage)
          .then((response) => {
            if (response.data.status === 'return') {
              core.showSnackbar('error', response.data.message)
            } else if (response.data.status === 'success') {
              core.showSnackbar('success', response.data.message)
              this.$bvModal.hide('message')
            } else if (response.data.status === 'upgrade') {
              this.$emit('upgrade_message', response.data.nextPlan)
              this.$bvModal.show('globalUpgrade')
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    setEndingDateAndTotalPrice () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        startingDate: this.startingDate,
        services: this.activeServices
      }
      Axios.post(API.API_SET_ENDING_DATE, postData)
        .then((response) => {
          if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.endingDate = response.data.message.endDate

            this.$refs.eventPriceSubtotal.innerHTML = response.data.message.totalPrice + ' ' + this.priceCurrency
            let totalPrice = response.data.message.totalPrice
            if (parseFloat(this.$refs.eventPriceDiscount.innerHTML) !== 0) {
              const theDiscount = parseFloat(this.$refs.eventPriceDiscount.innerHTML.substring(1))
              this.$refs.eventPriceDiscount.innerHTML = '-' + theDiscount + ' ' + this.priceCurrency
              totalPrice = response.data.message.totalPrice - theDiscount
            }
            this.$refs.eventPrice.innerHTML = totalPrice + ' ' + this.priceCurrency
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCompanyEvents (start, end) {
      const theDates = {
        start: start,
        end: end
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_EVENTS, theDates)
        .then((response) => {
          if (response.data.status === 'success') {
            this.events = response.data.events
            this.firstInterval = parseInt(response.data.startHour.split(':'))
            this.intervalCount = response.data.interval
            this.weekdaysInterval = response.data.workingDays
            this.breaks = response.data.breaks
            this.employeeBreaks = response.data.employee_breaks
            this.priceCurrency = response.data.events[0].currency
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCompanyEmployees () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const startingDateObj = this.startingDate // .split('T')
      const dataPost = {
        service: this.selectedServiceId,
        theDate: startingDateObj
      }
      Axios.post(API.API_EMPLOYEES_BY_SERVICE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            const empl = []
            const employeesPicture = []
            const theActiveEmployee = []
            for (let x = 0; x < response.data.employees.length; x++) {
              empl.push(response.data.employees[x].name)
              employeesPicture[response.data.employees[x].name] = response.data.employees[x].logo
              theActiveEmployee[response.data.employees[x].name] = {
                token: response.data.employees[x].token,
                description: response.data.employees[x].description,
                email: response.data.employees[x].email,
                phone: response.data.employees[x].phone,
                logo: response.data.employees[x].logo,
                name: response.data.employees[x].name
              }
            }
            this.categories = empl
            this.categoriesSelected = empl
            this.selectedEmployees = empl
            this.companyEmployeesPictures = employeesPicture
            this.companyActiveEmployee = theActiveEmployee
            this.companyEmployees = response.data.employees
            this.setCalendarWidth()
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCompanyServices () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        company: this.companyToken
      }
      if (this.$refs.employeeToken.value !== '') {
        postData.employee = this.$refs.employeeToken.value
      }
      Axios.post(API.API_SERVICES, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            const optionServicesObj = []
            for (let i = 0; i < response.data.services.length; i++) {
              for (let j = 0; j < response.data.services[i].list.length; j++) {
                optionServicesObj.push(response.data.services[i].list[j])
              }
            }
            this.optionsServices = optionServicesObj
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    changeServices () {
      this.getCompanyServices()
      this.$bvModal.show('theServices')
    },
    changeEmployee () {
      this.getCompanyEmployees()
      this.isHoliday = false
      this.$bvModal.show('theEmployee')
    },
    changeEmployeeHoliday () {
      this.getCompanyEmployees()
      this.isHoliday = true
      this.$bvModal.show('theEmployee')
    },
    addCustomer () {
      // check if first char is number or letter
      this.addCustomerEmail = ''
      if (this.searcheC.match(/^\d+/)) {
        this.addCustomerName = ''
        this.addCustomerPhone = this.searcheC
      } else {
        this.addCustomerName = this.searcheC
        this.addCustomerPhone = ''
      }
      this.newuser.logo = this.customer_image
      this.$bvModal.show('addCustomer')
    },
    saveCustomer () {
      if (!this.addCustomerName) {
        core.showSnackbar('error', this.$t('customer_.name_required'))
        return
      }
      if (!this.addCustomerPhone) {
        core.showSnackbar('error', this.$t('customer_.phone_required'))
        return
      }
      const formData = new FormData()
      if (this.$refs.fileNew.files[0]) {
        formData.append('file', this.$refs.fileNew.files[0])
      }
      let theCurrentName = this.addCustomerName
      if (this.addCustomerLastName !== '') {
        theCurrentName += ' ' + this.addCustomerLastName
      }
      formData.append('name', theCurrentName)
      if (this.addCustomerCNP !== '') {
        formData.append('cnp', this.addCustomerCNP)
      }
      formData.append('phone', this.addCustomerPhone)
      formData.append('email', this.addCustomerEmail)
      formData.append('company', this.companyToken)
      formData.append('password', this.addCustomerPassword || '')
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_CUSTOMER_DETAILS, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer.user_updated'))
            const theUser = response.data.theUser
            this.setCustomer(theUser)
            this.$refs.customerToken.value = theUser.token
            this.editCustomer()
            this.$bvModal.hide('addCustomer')
            this.$bvModal.show('editCustomer')
          } else if (response.data.status === 'upgrade') {
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          } else if (response.data.status === 'phone_exists') {
            core.showSnackbar('error', this.$t('customer_.phone_exists'))
          } else if (response.data.status === 'email_exists') {
            core.showSnackbar('error', this.$t('customer_.email_exists'))
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveCustomerEdit () {
      if (!this.addCustomerNameEdit) {
        core.showSnackbar('error', this.$t('customer_.name_required'))
        return
      }
      if (!this.addCustomerPhoneEdit) {
        core.showSnackbar('error', this.$t('customer_.phone_required'))
        return
      }
      const formData = new FormData()
      if (this.$refs.file.files[0]) {
        formData.append('file', this.$refs.file.files[0])
      }
      formData.append('name', this.addCustomerNameEdit)
      formData.append('lname', this.addCustomerLastNameEdit)
      formData.append('cnp', this.addCustomerCNPEdit)
      formData.append('phone', this.addCustomerPhoneEdit)
      formData.append('email', this.addCustomerEmailEdit)
      formData.append('token', this.addCustomerToken)
      formData.append('company', this.companyToken)
      formData.append('password', this.addCustomerPasswordEdit || '')
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_UPDATE_CUSTOMER, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer.user_updated'))
            const theUser = response.data.theUser
            this.setCustomer(theUser)
            this.$bvModal.hide('editCustomer')
          } else if (response.data.status === 'upgrade') {
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          } else if (response.data.status === 'phone_exists') {
            core.showSnackbar('error', this.$t('customer_.phone_exists'))
          } else if (response.data.status === 'email_exists') {
            core.showSnackbar('error', this.$t('customer_.email_exists'))
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    changeCustomers () {
      // this.getCompanyCustomers()
      this.$bvModal.show('theCustomers')
    },
    editCustomer () {
      if (!this.$refs.customerToken.value) {
        this.$bvModal.show('theCustomers')
      } else {
        const customerName = this.$refs.customerName.innerHTML.split(' ')
        this.addCustomerNameEdit = customerName[0]
        this.addCustomerLastNameEdit = customerName.slice(1).toString().replace(',', ' ')
        this.addCustomerPhoneEdit = this.$refs.customerPhone.innerHTML
        this.addCustomerCNPEdit = this.$refs.customerCNP.value
        this.addCustomerEmailEdit = this.$refs.customerEmail.innerHTML
        if (this.$refs.customer_logo.src) {
          this.user.logo = this.$refs.customer_logo.src
        }
        this.addCustomerToken = this.$refs.customerToken.value
        this.theVariant = 'details'
        this.$bvModal.show('editCustomer')
      }
    },
    editCustomerHistory () {
      this.$bvModal.show('editCustomer')
      this.theVariant = 'booking'
      this.displayLastBookings(this.$refs.customerToken.value)
    },
    editPatient () {
      this.templatesDisplay()
      this.getSavedRecord()
      this.getSavedDiagnosis()
      this.record = 'current_record'
      this.$bvModal.show('patient')
    },
    getCompanyCustomers (searchData) {
      const search = {
        search: searchData
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_CUSTOMERS_SEARCH, search)
        .then((response) => {
          if (response.data.status === 'success') {
            this.optionsCustomers = response.data.message
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setFavorite () {
      let favorit = 0
      if (this.$refs.customerFavorit.innerHTML === '<i class="ri-star-line"></i>') {
        favorit = '1'
        this.$refs.customerFavorit.innerHTML = '<i class="ri-star-fill"></i>'
      } else {
        favorit = '0'
        this.$refs.customerFavorit.innerHTML = '<i class="ri-star-line"></i>'
      }
      const postData = {
        favorite: favorit,
        token: this.$refs.customerToken.value
      }

      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_FAVORITE_CUSTOMERS, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.optionsCustomers = response.data.message
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    filteredListOnHold () {
    },
    filteredListCustomers () {
      const searchVal = this.searcheC.toLowerCase()
      if (this.globalTimeout !== null) {
        clearTimeout(this.globalTimeout)
      }
      const newThis = this
      this.globalTimeout = setTimeout(function () {
        newThis.globalTimeout = null
        if (searchVal.length > 2) {
          newThis.getCompanyCustomers(searchVal)
        } else {
          newThis.optionsCustomers = []
        }
      }, 500)
    },
    filteredListEmployee () {
      const searchValue1 = this.searche.toLowerCase()
      if (searchValue1.length > 0) {
        for (let i = 0; i < this.companyEmployees.length; i++) {
          if (this.companyEmployees[i].name.toLowerCase().includes(searchValue1)) {
            this.$refs['employee_' + this.companyEmployees[i].id][0].$el.classList.remove('d-none')
          } else {
            this.$refs['employee_' + this.companyEmployees[i].id][0].$el.classList.add('d-none')
          }
        }
      } else {
        for (let i = 0; i < this.companyEmployees.length; i++) {
          this.$refs['employee_' + this.companyEmployees[i].id][0].$el.classList.remove('d-none')
        }
      }
    },
    filteredListService () {
      const searchValue11 = this.searcheS.toLowerCase()
      if (searchValue11.length > 0) {
        for (let i = 0; i < this.optionsServices.length; i++) {
          if (this.optionsServices[i].name.toLowerCase().includes(searchValue11)) {
            this.$refs['services_' + this.optionsServices[i].id][0].$el.classList.remove('d-none')
          } else {
            this.$refs['services_' + this.optionsServices[i].id][0].$el.classList.add('d-none')
          }
        }
      } else {
        for (let i = 0; i < this.optionsServices.length; i++) {
          this.$refs['services_' + this.optionsServices[i].id][0].$el.classList.remove('d-none')
        }
      }
    },
    filteredListDiscount () {
      const searchValue1 = this.searchd.toLowerCase()
      if (searchValue1.length > 0) {
        for (let i = 0; i < this.companyDiscounts.length; i++) {
          if (this.companyDiscounts[i].name.toLowerCase().includes(searchValue1)) {
            this.$refs['discount_' + this.companyDiscounts[i].id][0].$el.classList.remove('d-none')
          } else {
            this.$refs['discount_' + this.companyDiscounts[i].id][0].$el.classList.add('d-none')
          }
        }
      } else {
        for (let i = 0; i < this.companyDiscounts.length; i++) {
          this.$refs['discount_' + this.companyDiscounts[i].id][0].$el.classList.remove('d-none')
        }
      }
    },
    setCustomer (item) {
      this.$refs.customerToken.value = item.token
      this.$refs.customerName.innerHTML = item.name
      this.$refs.customerEmail.innerHTML = item.email
      this.$refs.customerCNP.value = item.cnp
      this.customerTokenActive = true
      this.$refs.customerEmail.setAttribute('token', item.token)
      if (this.canSeePhone) {
        this.$refs.customerPhone.innerHTML = item.phone
        this.$refs.customerPhone.setAttribute('href', 'tel: ' + item.phone)
      }
      if (item.logo) {
        this.$refs.customer_logo.setAttribute('src', item.logo)
      }
      if (item.customer_booking) {
        this.$refs.customerBookings.innerHTML = item.customer_booking
      }
      if (item.customer_money_spent) {
        this.$refs.customerMoneySpent.innerHTML = item.customer_money_spent
      }
      this.$bvModal.hide('theCustomers')
    },
    setEmployee (item) {
      if (item === 'first') {
        // make call to get the first client with availability
      } else {
        // set client
        this.$refs.employeeToken.value = item.token
        this.$refs.employeeName.innerHTML = item.name
        this.$refs.employeeEmail.innerHTML = item.email
        this.$refs.employeeEmail.setAttribute('token', item.token)
        if (this.canSeePhone) {
          this.$refs.employeePhone.innerHTML = item.phone
          this.$refs.employeePhone.setAttribute('href', 'tel: ' + item.phone)
        }
        this.$refs.employeeBooking.innerHTML = item.noOfBookings
        this.$refs.employeeLogo.setAttribute('src', item.logo)
        if (this.isHoliday) {
          this.$refs.employeeTokenHoliday.value = item.token
          this.$refs.employeeNameHoliday.innerHTML = item.name
          this.$refs.employeeEmailHoliday.innerHTML = item.email
          this.$refs.employeeEmailHoliday.setAttribute('token', item.token)
          if (this.canSeePhone) {
            this.$refs.employeePhoneHoliday.innerHTML = item.phone
            this.$refs.employeePhoneHoliday.setAttribute('href', 'tel: ' + item.phone)
          }
          this.$refs.employeeLogoHoliday.setAttribute('src', item.logo)
        }
        // set availability
      }
      this.$bvModal.hide('theEmployee')
    },
    setService (item) {
      if (!this.activeServices) {
        this.activeServices = []
      }
      this.activeServices.push(item)
      this.setEndingDateAndTotalPrice()
      this.$bvModal.hide('theServices')
    },
    removeService (item) {
      const idx = this.activeServices.indexOf(item)
      this.activeServices.splice(idx, 1)
      this.setEndingDateAndTotalPrice()
    },
    openDelete () {
      if (!this.canEditEvents) {
        core.showSnackbar('error', 'You don\'t have rights to delete events')
        return
      }
      const eventId = this.$refs.eventID.value
      this.selectedOnHoldToken = null
      this.getOnHoldEvents(eventId)
      this.$bvModal.show('onHoldBooking')
      // get first 20 onhold event from that date
    },
    getOnHoldEvents (eventId, number, search) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        except: eventId,
        number: number,
        search: search
      }
      Axios.post(API.API_GET_ONHOLD_EVENTS, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.onHoldEvents = response.data.events
          } else if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    replaceEvent () {
      const eventId = this.$refs.eventID.value
      if (!this.selectedOnHoldToken) {
        core.showSnackbar('error', 'Please select from On Hold list')
      } else {
        this.changeEventWithOnHold(eventId, this.selectedOnHoldToken)
        this.deleteEvent()
        this.$bvModal.hide('modal')
      }
    },
    changeEventWithOnHold (from, to) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        from: from,
        to: to
      }
      Axios.post(API.API_CHANGE_EVENT_WITH_ONHOLD, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', response.data.message)
          } else if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    deleteEvent () {
      const eventId = this.$refs.eventID.value
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        eventToken: eventId
      }
      Axios.post(API.API_DELETE_EVENT, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            const idx = this.events.findIndex(item => item.id === eventId)
            this.events.splice(idx, 1)
            this.$bvModal.hide('onHoldBooking')
            this.$bvModal.hide('modal')
            this.$bvModal.hide('holiday')
            this.getCompanyEvents(this.theStart, this.theEnd)
            core.showSnackbar('success', response.data.message)
          } else if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveEvent () {
      if (!this.canEditEvents) {
        core.showSnackbar('error', 'Nu ai destule drepturi sa salvezi')
        return
      }
      if (!this.activeServices) {
        core.showSnackbar('error', this.$t('calendar.add_service'))
        return
      }
      if (this.$refs.employeeToken.value === '') {
        core.showSnackbar('error', this.$t('employees_list.add_employee'))
        return
      }
      if (this.$refs.customerToken.value === '') {
        core.showSnackbar('error', this.$t('customer.add_customer'))
        return
      }
      if (!this.startingDate) {
        core.showSnackbar('error', this.$t('feature.missing_starting_date'))
        return
      }
      this.isLoadingEvent = true
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      console.log('saveEvent: ', this.$refs.eventID.value)
      const postData = {
        eventToken: this.$refs.eventID.value,
        service: this.activeServices,
        employee: this.$refs.employeeToken.value,
        customer: this.$refs.customerToken.value,
        start: this.startingDate,
        end: this.endingDate,
        payment: this.$refs.haspayed.value,
        amount: this.$refs.eventPrice.innerHTML,
        subtotal: this.$refs.eventPriceSubtotal.innerHTML,
        discount: this.$refs.eventPriceDiscount.innerHTML,
        voucher: this.$refs.eventPriceDiscountToken.innerHTML,
        description: this.descriptionEvent || ''
      }
      Axios.post(API.API_SAVE_EVENT, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', response.data.message)
            this.getCompanyEvents(this.theStart, this.theEnd)
            this.isLoadingEvent = false
            this.$bvModal.hide('modal')
          } else if (response.data.status === 'fail') {
            this.isLoadingEvent = false
            this.$bvModal.hide('modal')
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'upgrade') {
            this.isLoadingEvent = false
            this.$bvModal.hide('modal')
            this.$emit('upgrade_message', response.data.nextPlan)
            this.$bvModal.show('globalUpgrade')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveEventHoliday () {
      // if (!this.canEditEvents) {
      //   core.showSnackbar('error', 'You don\'t have rights to save event')
      //   return
      // }
      if (this.startingDateHoliday === '') {
        core.showSnackbar('error', this.$t('feature.missing_starting_date'))
        return
      } else if (this.endingDateHoliday === '') {
        core.showSnackbar('error', this.$t('feature.missing_ending_date'))
        return
      } else if (this.$refs.employeeTokenHoliday.value === '') {
        core.showSnackbar('error', this.$t('feature.missing_employee'))
        return
      } else if (this.descriptionHoliday === '') {
        core.showSnackbar('error', this.$t('feature.missing_description'))
        return
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        eventToken: this.$refs.eventIDHoliday.value,
        service: null,
        employee: this.$refs.employeeTokenHoliday.value,
        customer: null,
        start: this.startingDateHoliday,
        end: this.endingDateHoliday,
        payment: null,
        amount: null,
        description: this.descriptionHoliday,
        holiday: 1
      }
      Axios.post(API.API_SAVE_EVENT, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', response.data.message)
            this.getCompanyEvents(this.theStart, this.theEnd)
            this.$bvModal.hide('holiday')
          } else if (response.data.status === 'fail') {
            core.showSnackbar('error', response.data.message)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setPayment (type) {
      this.$refs.haspayed.value = type
    },
    getCurrentTime () {
      return this.$refs.calendar ? this.$refs.calendar.times.now.hour * 60 + this.$refs.calendar.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)
      this.$refs.calendar.scrollToTime(first)
    },
    updateTime () {
      const thisOne = this
      setInterval(() => {
        thisOne.$refs.calendar.updateTimes()
      }, 60 * 1000)
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'category'
    },
    openNewEvent (event) {
      if (this.type !== 'category') {
        this.$refs.eventID.value = ''
        const mouse = this.roundTime(this.toTimeNew(event.date + ' ' + event.time))
        this.startingDate = this.timestampToDateCalendar(mouse)
        this.endingDate = this.timestampToDateCalendar(mouse)
        this.activeServices = []
        this.$refs.employeeToken.value = ''
        this.$refs.employeeTokenHoliday.value = ''
        this.$refs.employeeName.innerHTML = this.$t('employees_list.add_employee')
        this.$refs.employeeNameHoliday.innerHTML = this.$t('employees_list.add_employee')
        this.$refs.employeeEmail.innerHTML = ''
        this.$refs.employeeEmailHoliday.innerHTML = ''
        this.$refs.employeeLogo.setAttribute('src', this.employee_image)
        this.$refs.employeeLogoHoliday.setAttribute('src', this.employee_image)

        this.$refs.employeePhone.innerHTML = ''
        this.$refs.employeePhoneHoliday.innerHTML = ''

        this.$refs.customerToken.value = ''
        this.$refs.customerNew.innerHTML = ''
        this.$refs.customerName.innerHTML = this.$t('customer.add_customer')
        this.$refs.customerEmail.innerHTML = ''
        this.$refs.customerPhone.innerHTML = ''
        this.$refs.customer_logo.setAttribute('src', this.customer_image)

        this.$refs.eventPriceSubtotal.innerHTML = '0 ' + this.priceCurrency
        this.selectedDiscountValue = 0
        this.addDiscountValue = false

        this.$refs.eventPriceDiscountToken.innerHTML = ''
        this.$refs.eventPriceDiscount.innerHTML = 0
        this.$refs.eventPrice.innerHTML = '0 ' + this.priceCurrency
        this.$refs.eventPricePayed.innerHTML = '0 ' + this.priceCurrency

        this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.not_payed')

        this.$refs.eventPaymentSelected.innerHTML = ''

        this.$refs.addedBy.innerHTML = ''
        this.$refs.tokenId.innerHTML = ''

        console.log('show reminder')
        this.showEventModal()
      }
    },
    openNewEventCategory (event) {
      this.$refs.eventID.value = ''
      const mouse = this.roundTime(this.toTimeNew(event.date + ' ' + event.time))
      this.startingDate = this.timestampToDateCalendar(mouse)
      this.endingDate = this.timestampToDateCalendar(mouse)

      this.activeServices = null
      const theCat = this.companyActiveEmployee[event.category]
      this.$refs.employeeToken.value = theCat.token ? theCat.token : ''
      this.$refs.employeeTokenHoliday.value = theCat.token ? theCat.token : ''
      this.$refs.employeeName.innerHTML = theCat.name ? theCat.name : this.$t('employees_list.add_employee')
      this.$refs.employeeNameHoliday.innerHTML = theCat.name ? theCat.name : this.$t('employees_list.add_employee')
      this.$refs.employeeEmail.innerHTML = theCat.email ? theCat.email : ''
      this.$refs.employeeEmailHoliday.innerHTML = theCat.email ? theCat.email : ''
      this.$refs.employeeEmail.setAttribute('token', theCat.token)
      this.$refs.employeeEmailHoliday.setAttribute('token', theCat.token)
      if (this.canSeePhone) {
        this.$refs.employeePhone.innerHTML = theCat.phone ? theCat.phone : ''
        this.$refs.employeePhone.setAttribute('href', 'tel: ' + theCat.phone)
        this.$refs.employeePhoneHoliday.innerHTML = theCat.phone ? theCat.phone : ''
        this.$refs.employeePhoneHoliday.setAttribute('href', 'tel: ' + theCat.phone)
      }
      if (theCat.logo !== null) {
        this.$refs.employeeLogo.setAttribute('src', theCat.logo)
        this.$refs.employeeLogoHoliday.setAttribute('src', theCat.logo)
      }

      this.$refs.customerToken.value = ''
      this.$refs.customerNew.innerHTML = ''
      this.$refs.customerName.innerHTML = this.$t('customer.add_customer')
      this.$refs.customerEmail.innerHTML = ''
      this.$refs.customerPhone.innerHTML = ''
      this.$refs.customer_logo.setAttribute('src', this.customer_image)

      this.$refs.eventPriceSubtotal.innerHTML = '0 ' + this.priceCurrency
      this.selectedDiscountValue = 0
      this.addDiscountValue = false

      this.$refs.eventPriceDiscountToken.innerHTML = ''
      this.$refs.eventPriceDiscount.innerHTML = 0
      this.$refs.eventPrice.innerHTML = '0 ' + this.priceCurrency
      this.$refs.eventPricePayed.innerHTML = '0 ' + this.priceCurrency

      this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.not_payed')

      this.$refs.eventPaymentSelected.innerHTML = ''

      this.$refs.addedBy.innerHTML = ''
      this.$refs.tokenId.innerHTML = ''
      this.showEventModal()
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
      this.updateCalendarName()
    },
    setCalendarType (theType) {
      this.type = theType
      this.changeSlot()
    },
    saveCalendarSettings () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const calendar = {
        slot: this.slotSelect,
        working: this.isWorking,
        workingDays: this.isWorkingDays,
        calendarType: this.type
      }
      Axios.post(API.API_SAVE_CALENDAR_SETTINGS, calendar)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('calendar.calendar_saved'))
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    getCalendarSettings () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_CALENDAR_SETTINGS)
        .then((response) => {
          if (response.data.status === 'success') {
            const calendarSettings = JSON.parse(response.data.calendar.calendar)
            this.slotSelect = calendarSettings.slot
            this.isWorking = calendarSettings.working
            this.isWorkingDays = calendarSettings.workingDays
            this.type = calendarSettings.calendarType
            let firstInterval = 0
            let intervalCount = 24
            const intervalMinutes = this.slotSelect
            if (!this.isWorking) {
              firstInterval = 0
              intervalCount = 24 * 60 / intervalMinutes
            } else {
              firstInterval = this.firstInterval * 60 / intervalMinutes - 1
              intervalCount = this.intervalCount * 60 / intervalMinutes + 2
            }

            if (!this.isWorkingDays) {
              this.weekdays = [1, 2, 3, 4, 5, 6, 0]
            } else {
              this.weekdays = this.weekdaysInterval
            }

            const thisOne = this
            setTimeout(() => {
              thisOne.ready = true
              thisOne.intervalMinutes1 = intervalMinutes
              thisOne.intervalFirst1 = firstInterval
              thisOne.intervalCount1 = intervalCount
              thisOne.isLoading = false
            }, 100)
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    changeSlot () {
      let firstInterval = 0
      let intervalCount = 24
      const intervalMinutes = this.slotSelect
      if (!this.isWorking) {
        firstInterval = 0
        intervalCount = 24 * 60 / intervalMinutes
      } else {
        firstInterval = this.firstInterval * 60 / intervalMinutes - 1
        intervalCount = this.intervalCount * 60 / intervalMinutes + 2
      }

      this.intervalMinutes1 = intervalMinutes
      this.intervalFirst1 = firstInterval
      this.intervalCount1 = intervalCount
      this.$refs.calendar.intervalHeight = 3 * this.slotSelect
      console.log(intervalMinutes, firstInterval, intervalCount)
      this.saveCalendarSettings()
    },
    setWorking () {
      this.isWorking = !this.isWorking
      this.changeSlot()
    },
    setWorkingDays () {
      if (this.isWorkingDays) {
        this.weekdays = [1, 2, 3, 4, 5, 6, 0]
        this.isWorkingDays = false
      } else {
        this.weekdays = this.weekdaysInterval
        this.isWorkingDays = true
      }
      this.saveCalendarSettings()
    },
    prev () {
      this.$refs.calendar.prev()
      this.updateCalendarName()
    },
    next () {
      this.$refs.calendar.next()
      this.updateCalendarName()
    },
    updateCalendarName () {
      setTimeout(() => {
        this.calendarName = this.getDayName(new Date(this.$refs.calendar.lastStart.date), this.getLocaleByLang()) + ' ' + this.$refs.calendar.lastStart.day + ' ' + this.$refs.calendar.title
      }, 200)
    },
    getLocaleByLang () {
      console.log(this.$i18n.locale)
      const theLocalLang = this.$i18n.locale
      let theLocalLangCode = ''
      if (theLocalLang === 'ro') {
        theLocalLangCode = 'ro-RO'
      } else if (theLocalLang === 'de') {
        theLocalLangCode = 'de-DE'
      } else if (theLocalLang === 'en') {
        theLocalLangCode = 'en-US'
      } else if (theLocalLang === 'fr') {
        theLocalLangCode = 'fr-FR'
      } else if (theLocalLang === 'it') {
        theLocalLangCode = 'it-IT'
      } else if (theLocalLang === 'es') {
        theLocalLangCode = 'es-ES'
      } else if (theLocalLang === 'hu') {
        theLocalLangCode = 'hu-HU'
      }

      return theLocalLangCode
    },
    getDayName (date = new Date(), locale = 'en-US') {
      return date.toLocaleDateString(locale, { weekday: 'long' })
    },
    increment () {
      this.startingCounter = this.startingCounter + 1
    },
    setTheTimeout () {
      this.increment()
      this.interval = setInterval(this.increment, 100)
    },
    showEvent ({ nativeEvent, event }) {
      console.log(nativeEvent, event)
      this.customerTokenActive = false
      const theCounter = this.startingCounter
      this.startingCounter = 0
      clearInterval(this.interval)
      if (theCounter > 3) {
        return
      }

      const open = () => {
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      this.$refs.haspayed.value = ''
      this.getCompanyEmployees()
      this.getCompanyServices()
      this.activeServices = event.service
      this.$refs.eventID.value = event.id ? event.id : ''
      console.log('showEvent ', this.$refs.eventID.value)
      this.$refs.eventIDHoliday.value = event.id ? event.id : ''

      if (event.employee_token) {
        this.isVisible = true
        this.customerTokenActive = true
      } else {
        this.isVisible = false
      }
      this.$refs.employeeToken.value = event.employee_token ? event.employee_token : ''
      this.$refs.employeeTokenHoliday.value = event.employee_token ? event.employee_token : ''
      this.$refs.employeeName.innerHTML = event.employee_fname ? event.employee_fname + ' ' + event.employee_lname : this.$t('employees_list.add_employee')
      this.$refs.employeeNameHoliday.innerHTML = event.employee_fname ? event.employee_fname + ' ' + event.employee_lname : this.$t('employees_list.add_employee')
      this.$refs.employeeEmail.innerHTML = event.employee_email ? event.employee_email : ''
      this.$refs.employeeEmailHoliday.innerHTML = event.employee_email ? event.employee_email : ''
      this.$refs.employeeEmail.setAttribute('token', event.employee_token)
      this.$refs.employeeEmailHoliday.setAttribute('token', event.employee_token)
      if (this.canSeePhone) {
        this.$refs.employeePhone.innerHTML = event.employee_phone ? event.employee_phone : ''
        this.$refs.employeePhone.setAttribute('href', 'tel: ' + event.employee_phone)
        this.$refs.employeePhoneHoliday.innerHTML = event.employee_phone ? event.employee_phone : ''
        this.$refs.employeePhoneHoliday.setAttribute('href', 'tel: ' + event.employee_phone)
      }
      this.$refs.employeeBooking.innerHTML = event.employee_booking_in_date
      if (event.employee_logo && event.employee_logo !== null && event.employee_logo !== '' && event.employee_logo !== 'undefined') {
        this.$refs.employeeLogo.setAttribute('src', event.employee_logo)
        this.$refs.employeeLogoHoliday.setAttribute('src', event.employee_logo)
      } else {
        this.$refs.employeeLogo.setAttribute('src', this.employee_image)
        this.$refs.employeeLogoHoliday.setAttribute('src', this.employee_image)
      }

      // if is category view get employee by token
      if (this.type === 'category' && !event.id) {
        const theCat = this.companyActiveEmployee[this.enteredCategory]
        this.$refs.employeeToken.value = theCat.token ? theCat.token : ''
        this.$refs.employeeTokenHoliday.value = theCat.token ? theCat.token : ''
        this.$refs.employeeName.innerHTML = theCat.name ? theCat.name : this.$t('employees_list.add_employee')
        this.$refs.employeeNameHoliday.innerHTML = theCat.name ? theCat.name : this.$t('employees_list.add_employee')
        this.$refs.employeeEmail.innerHTML = theCat.email ? theCat.email : ''
        this.$refs.employeeEmailHoliday.innerHTML = theCat.email ? theCat.email : ''
        this.$refs.employeeEmail.setAttribute('token', theCat.token)
        this.$refs.employeeEmailHoliday.setAttribute('token', theCat.token)
        if (this.canSeePhone) {
          this.$refs.employeePhone.innerHTML = theCat.phone ? theCat.phone : ''
          this.$refs.employeePhone.setAttribute('href', 'tel: ' + theCat.phone)
          this.$refs.employeePhoneHoliday.innerHTML = theCat.phone ? theCat.phone : ''
          this.$refs.employeePhoneHoliday.setAttribute('href', 'tel: ' + theCat.phone)
        }
        if (theCat.logo !== null) {
          this.$refs.employeeLogo.setAttribute('src', theCat.logo)
          this.$refs.employeeLogoHoliday.setAttribute('src', theCat.logo)
        }
      }

      // console.log(event.start.toString(), event.start.toString().length)

      if (event.start.toString().length === 13) {
        const data1 = this.timestampToDateCalendar(event.start).toString()
        const data2 = this.timestampToDateCalendar(event.end).toString()
        // console.log(data1, data2)
        this.startingDate = data1
        this.endingDate = data2
        this.startingDateHoliday = data1
        this.endingDateHoliday = data2
      } else if (event.start.toString().length > 60) {
        const data1 = this.timestampToDateCalendar64(event.start).toString()
        const data2 = this.timestampToDateCalendar64(event.end).toString()
        this.startingDate = data1
        this.endingDate = data2
        this.startingDateHoliday = data1
        this.endingDateHoliday = data2
      } else {
        this.startingDate = event.start.toString().replace(' ', 'T')
        this.endingDate = event.end.toString().replace(' ', 'T')
        this.startingDateHoliday = this.startingDate
        this.endingDateHoliday = this.endingDate
      }
      this.$refs.customerToken.value = event.customer_token ? event.customer_token : ''
      this.$refs.customerNew.innerHTML = event.customer_new ? event.customer_new : ''
      this.$refs.customerName.innerHTML = event.customer_name ? event.customer_name : this.$t('customer.add_customer')
      this.$refs.customerEmail.innerHTML = event.customer_email ? event.customer_email : ''
      this.$refs.customerEmail.setAttribute('token', event.customer_token)
      if (this.canSeePhone) {
        this.$refs.customerPhone.innerHTML = event.customer_phone ? event.customer_phone : ''
        this.$refs.customerPhone.setAttribute('href', 'tel: ' + event.customer_phone)
      }
      if (event.customer_favorit === '1') {
        this.$refs.customerFavorit.innerHTML = '<i class="ri-star-fill"></i>'
      } else {
        this.$refs.customerFavorit.innerHTML = '<i class="ri-star-line"></i>'
      }
      if (event.customer_logo && event.customer_logo !== null && event.customer_logo !== '' && event.customer_logo !== 'undefined') {
        this.$refs.customer_logo.setAttribute('src', event.customer_logo)
      } else {
        this.$refs.customer_logo.setAttribute('src', this.customer_image)
      }
      this.$refs.customerBookings.innerHTML = event.customer_booking ? event.customer_booking : ''
      this.$refs.customerMoneySpent.innerHTML = event.customer_money_spent ? event.customer_money_spent : ''

      if (!event.subtotal) {
        this.$refs.eventPriceSubtotal.innerHTML = '0 ' + this.priceCurrency
      } else {
        this.$refs.eventPriceSubtotal.innerHTML = event.subtotal ? event.subtotal + ' ' + event.currency : '0 ' + event.currency
      }

      this.selectedDiscountValue = event.discount ? parseFloat(event.discount) : 0
      if (!event.discount) {
        this.addDiscountValue = false
      } else if (parseFloat(event.discount) !== 0) {
        this.addDiscountValue = true
      }
      this.$refs.eventPriceDiscountToken.innerHTML = event.voucher ? event.voucher : ''
      this.$refs.eventPriceDiscount.innerHTML = '-' + this.selectedDiscountValue + ' ' + this.priceCurrency
      this.$refs.eventPrice.innerHTML = event.price ? event.price + ' ' + event.currency : '0 ' + this.priceCurrency
      this.$refs.eventPricePayed.innerHTML = event.pricePayed !== '' ? event.pricePayed + ' ' + event.currency : '0 ' + this.priceCurrency

      if (!event.pricePayed) {
        this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.not_payed')
      } else {
        if (event.pricePayed !== event.price) {
          this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.not_payed')
        } else {
          this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.payed')
        }
      }
      this.$refs.eventPaymentSelected.innerHTML = event.payment_type ? event.payment_type : ''
      this.thepayment = event.payment_final_status
      this.$refs.haspayed.value = event.payment_final_status

      this.$refs.addedBy.innerHTML = event.addedBy ? event.addedBy : ''
      this.$refs.createdAt.innerHTML = event.createdAt
      this.$refs.tokenId.innerHTML = event.id

      if (parseInt(event.holiday) === 1) {
        this.descriptionHoliday = event.description
        this.$bvModal.show('holiday')
      } else {
        this.descriptionEvent = event.description
        this.$bvModal.show('modal')
      }

      this.startingDateReminder = this.startingDate
      this.startingDateReminderMin = this.startingDate

      const postData = {
        booking: event.id
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_REMINDER, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            const theReminder = response.data.reminder
            if (theReminder) {
              this.hasReminder = true
              this.startingDateReminder = theReminder.theDate
              this.startingDateReminderMin = theReminder.theDate
              this.reminderText = theReminder.message
            } else {
              this.hasReminder = false
              this.startingDateReminder = ''
              this.startingDateReminderMin = ''
              this.reminderText = ''
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })

      nativeEvent.stopPropagation()
    },
    openSearchResult () {
      const event = this.searchResults
      this.customerTokenActive = false
      const theCounter = this.startingCounter
      this.startingCounter = 0
      clearInterval(this.interval)
      if (theCounter > 3) {
        return
      }

      this.$refs.haspayed.value = ''
      this.getCompanyEmployees()
      this.getCompanyServices()
      this.activeServices = event.service
      this.$refs.eventID.value = event.id ? event.id : ''
      this.$refs.eventIDHoliday.value = event.id ? event.id : ''

      if (event.employee_token) {
        this.isVisible = true
        this.customerTokenActive = true
      } else {
        this.isVisible = false
      }
      this.$refs.employeeToken.value = event.employee_token ? event.employee_token : ''
      this.$refs.employeeTokenHoliday.value = event.employee_token ? event.employee_token : ''
      this.$refs.employeeName.innerHTML = event.employee_fname ? event.employee_fname + ' ' + event.employee_lname : this.$t('employees_list.add_employee')
      this.$refs.employeeNameHoliday.innerHTML = event.employee_fname ? event.employee_fname + ' ' + event.employee_lname : this.$t('employees_list.add_employee')
      this.$refs.employeeEmail.innerHTML = event.employee_email ? event.employee_email : ''
      this.$refs.employeeEmailHoliday.innerHTML = event.employee_email ? event.employee_email : ''
      this.$refs.employeeEmail.setAttribute('token', event.employee_token)
      this.$refs.employeeEmailHoliday.setAttribute('token', event.employee_token)
      if (this.canSeePhone) {
        this.$refs.employeePhone.innerHTML = event.employee_phone ? event.employee_phone : ''
        this.$refs.employeePhone.setAttribute('href', 'tel: ' + event.employee_phone)
        this.$refs.employeePhoneHoliday.innerHTML = event.employee_phone ? event.employee_phone : ''
        this.$refs.employeePhoneHoliday.setAttribute('href', 'tel: ' + event.employee_phone)
      }
      this.$refs.employeeBooking.innerHTML = event.employee_booking_in_date
      if (event.employee_logo && event.employee_logo !== null && event.employee_logo !== '' && event.employee_logo !== 'undefined') {
        this.$refs.employeeLogo.setAttribute('src', event.employee_logo)
        this.$refs.employeeLogoHoliday.setAttribute('src', event.employee_logo)
      } else {
        this.$refs.employeeLogo.setAttribute('src', this.employee_image)
        this.$refs.employeeLogoHoliday.setAttribute('src', this.employee_image)
      }

      // if is category view get employee by token
      if (this.type === 'category' && !event.id) {
        const theCat = this.companyActiveEmployee[this.enteredCategory]
        this.$refs.employeeToken.value = theCat.token ? theCat.token : ''
        this.$refs.employeeTokenHoliday.value = theCat.token ? theCat.token : ''
        this.$refs.employeeName.innerHTML = theCat.name ? theCat.name : this.$t('employees_list.add_employee')
        this.$refs.employeeNameHoliday.innerHTML = theCat.name ? theCat.name : this.$t('employees_list.add_employee')
        this.$refs.employeeEmail.innerHTML = theCat.email ? theCat.email : ''
        this.$refs.employeeEmailHoliday.innerHTML = theCat.email ? theCat.email : ''
        this.$refs.employeeEmail.setAttribute('token', theCat.token)
        this.$refs.employeeEmailHoliday.setAttribute('token', theCat.token)
        if (this.canSeePhone) {
          this.$refs.employeePhone.innerHTML = theCat.phone ? theCat.phone : ''
          this.$refs.employeePhone.setAttribute('href', 'tel: ' + theCat.phone)
          this.$refs.employeePhoneHoliday.innerHTML = theCat.phone ? theCat.phone : ''
          this.$refs.employeePhoneHoliday.setAttribute('href', 'tel: ' + theCat.phone)
        }
        if (theCat.logo !== null) {
          this.$refs.employeeLogo.setAttribute('src', theCat.logo)
          this.$refs.employeeLogoHoliday.setAttribute('src', theCat.logo)
        }
      }

      // console.log(event.start.toString(), event.start.toString().length)

      if (event.start.toString().length === 13) {
        const data1 = this.timestampToDateCalendar(event.start).toString()
        const data2 = this.timestampToDateCalendar(event.end).toString()
        // console.log(data1, data2)
        this.startingDate = data1
        this.endingDate = data2
        this.startingDateHoliday = data1
        this.endingDateHoliday = data2
      } else if (event.start.toString().length > 60) {
        const data1 = this.timestampToDateCalendar64(event.start).toString()
        const data2 = this.timestampToDateCalendar64(event.end).toString()
        this.startingDate = data1
        this.endingDate = data2
        this.startingDateHoliday = data1
        this.endingDateHoliday = data2
      } else {
        this.startingDate = event.start.toString().replace(' ', 'T')
        this.endingDate = event.end.toString().replace(' ', 'T')
        this.startingDateHoliday = this.startingDate
        this.endingDateHoliday = this.endingDate
      }
      this.$refs.customerToken.value = event.customer_token ? event.customer_token : ''
      this.$refs.customerNew.innerHTML = event.customer_new ? event.customer_new : ''
      this.$refs.customerName.innerHTML = event.customer_name ? event.customer_name : this.$t('customer.add_customer')
      this.$refs.customerEmail.innerHTML = event.customer_email ? event.customer_email : ''
      this.$refs.customerEmail.setAttribute('token', event.customer_token)
      if (this.canSeePhone) {
        this.$refs.customerPhone.innerHTML = event.customer_phone ? event.customer_phone : ''
        this.$refs.customerPhone.setAttribute('href', 'tel: ' + event.customer_phone)
      }
      if (event.customer_favorit === '1') {
        this.$refs.customerFavorit.innerHTML = '<i class="ri-star-fill"></i>'
      } else {
        this.$refs.customerFavorit.innerHTML = '<i class="ri-star-line"></i>'
      }
      if (event.customer_logo && event.customer_logo !== null && event.customer_logo !== '' && event.customer_logo !== 'undefined') {
        this.$refs.customer_logo.setAttribute('src', event.customer_logo)
      } else {
        this.$refs.customer_logo.setAttribute('src', this.customer_image)
      }
      this.$refs.customerBookings.innerHTML = event.customer_booking ? event.customer_booking : ''
      this.$refs.customerMoneySpent.innerHTML = event.customer_money_spent ? event.customer_money_spent : ''

      if (!event.subtotal) {
        this.$refs.eventPriceSubtotal.innerHTML = '0 ' + this.priceCurrency
      } else {
        this.$refs.eventPriceSubtotal.innerHTML = event.subtotal ? event.subtotal + ' ' + event.currency : '0 ' + event.currency
      }

      this.selectedDiscountValue = event.discount ? parseFloat(event.discount) : 0
      if (!event.discount) {
        this.addDiscountValue = false
      } else if (parseFloat(event.discount) !== 0) {
        this.addDiscountValue = true
      }
      this.$refs.eventPriceDiscountToken.innerHTML = event.voucher ? event.voucher : ''
      this.$refs.eventPriceDiscount.innerHTML = '-' + this.selectedDiscountValue + ' ' + this.priceCurrency
      this.$refs.eventPrice.innerHTML = event.price ? event.price + ' ' + event.currency : '0 ' + this.priceCurrency
      this.$refs.eventPricePayed.innerHTML = event.pricePayed !== '' ? event.pricePayed + ' ' + event.currency : '0 ' + this.priceCurrency

      if (!event.pricePayed) {
        this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.not_payed')
      } else {
        if (event.pricePayed !== event.price) {
          this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.not_payed')
        } else {
          this.$refs.eventPaymentStatus.innerHTML = this.$t('calendar.payed')
        }
      }
      this.$refs.eventPaymentSelected.innerHTML = event.payment_type ? event.payment_type : ''
      this.thepayment = event.payment_final_status
      this.$refs.haspayed.value = event.payment_final_status

      this.$refs.addedBy.innerHTML = event.addedBy ? event.addedBy : ''
      this.$refs.createdAt.innerHTML = event.createdAt
      this.$refs.tokenId.innerHTML = event.id

      if (parseInt(event.holiday) === 1) {
        this.descriptionHoliday = event.description
        this.$bvModal.show('holiday')
      } else {
        this.descriptionEvent = event.description
        this.$bvModal.show('modal')
      }
      this.$bvModal.hide('searchBooking')
    },
    updateRange ({ start, end }) {
      this.theStart = start
      this.theEnd = end
      if (this.type !== 'month') {
        this.getCompanyEvents(start, end)
      } else {
        this.events = []
      }
    },
    startDrag ({ event, timed }) {
      if (event) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime (tms) {
      const mouse = this.toTime(tms)

      const currentTime = new Date().getTime()
      if (!this.addPast && currentTime > mouse) {
        core.showSnackbar('error', this.$t('feature.cannot_add_past_events'))
        return
      }
      if (this.dragEvent && this.dragTime === null) {
        // Drag existing Event
        const start = this.dragEvent.dragstart
        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        // get current category if any
        let theCategory = ''
        if (this.type === 'category') {
          theCategory = this.enteredCategory
        }
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: this.colors[0],
          start: this.createStart,
          end: this.createStart,
          timed: true,
          category: theCategory
        }
        this.events.push(this.createEvent)
      }
    },
    extendBottom (event) {
      this.createEvent = event
      this.createStart = this.toTimeNew(event.start)
      this.extendOriginal = this.toTimeNew(event.end)
      this.dragStartDate = event.start
      this.dragEndDate = event.end
    },
    mouseIsMoving (e) {
      const leftSide = e.pageX
      const rightSide = e.pageY - window.scrollY
      const hourTooltip = document.getElementById('hourTooltip')
      if (hourTooltip) {
        if (this.hourTooltip) {
          hourTooltip.style.top = rightSide + 'px'
          hourTooltip.style.left = leftSide + 'px'
          hourTooltip.style.display = 'block'
        } else {
          hourTooltip.style.display = 'none'
        }
      }
    },
    mouseExit () {
      this.hourTooltip = false
    },
    mouseMove (tms) {
      if (tms.time !== '') {
        this.hourTooltip = tms.time
      } else {
        this.hourTooltip = false
      }
      const mouse = this.toTime(tms)
      const currentTime = new Date().getTime()
      if (!this.addPast && currentTime > mouse && (this.dragEvent || this.createEvent)) {
        core.showSnackbar('error', this.$t('feature.cannot_add_past_events'))
        return
      }
      if (this.dragEvent && this.dragTime !== null) {
        if (!this.setDraged) {
          this.setDraged = mouse
        } else {
          const theDifference = mouse - this.setDraged

          if (theDifference > 120 || theDifference < -120) {
            const start = this.toTimeNew(this.dragEvent.start)
            const end = this.toTimeNew(this.dragEvent.end)
            const duration = (end - start)
            if (!this.isDraggingEvent) {
              this.isDraggingEvent = mouse - start
            }
            const newStart = this.roundTime(mouse - this.isDraggingEvent)
            const newEnd = newStart + duration

            this.dragEvent.start = this.timestampToDateCal(newStart)
            this.dragEvent.end = this.timestampToDateCal(newEnd)
            this.dragEvent.category = this.enteredCategory
          }
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)
        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    mouseMoveCategory (tms, category) {
      this.currentCategory = tms.category
    },
    endDrag (theEvent, plm) {
      this.isDraggingEvent = false
      if (!this.canEditEvents) {
        core.showSnackbar('error', 'You don\'t have rights to edit bookings')
        return
      }
      const zaTime = []
      if (this.createEvent) {
        if (this.dragStartDate && this.dragStartDate.length !== 13) {
          zaTime.start = this.dragStartDate
        }
        zaTime.end = theEvent.date + ' ' + theEvent.time + ':00'
        zaTime.id = this.createEvent.id
        zaTime.type = 1
        zaTime.category = ''
      } else if (this.dragEvent) {
        zaTime.start = this.timestampToDateCal(this.dragEvent.start)
        zaTime.end = this.timestampToDateCal(this.dragEvent.end)
        zaTime.id = this.dragEvent.id
        zaTime.type = 2
        zaTime.category = this.dragEvent.category
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const dateChanges = {
        start: zaTime.start,
        end: zaTime.end,
        id: zaTime.id,
        type: zaTime.type,
        category: zaTime.category
      }
      Axios.post(API.API_UPDATE_EVENT_DATE, dateChanges)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', response.data.message)
            this.getCompanyEvents(this.theStart, this.theEnd)
          }
        })
        .catch((error) => {
          console.error(error.response)
        })

      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
      this.setDraged = false
    },
    cancelDrag () {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    setCategory (cat) {
      this.enteredCategory = cat.category
    },
    bla (aa) {
      // console.log(aa)
    },
    roundTime (time, down = true) {
      const roundTo = 5 // this.slotSelect || 30 // minutes
      const roundDownTime = roundTo * 60 * 1000
      const valueAble = down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))

      return valueAble
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    toTimeNew (tms) {
      return new Date(tms).getTime()
    },
    timestampToDateCal (tms) {
      return new Date(tms)
      // return dateObjs.getFullYear() + '-' + dateObjs.getUTCMonth() + '-' + dateObjs.getUTCDay() + ' ' + dateObjs.getUTCHours() + ':' + dateObjs.getUTCMinutes() + ':' + dateObjs.getUTCSeconds()
    },
    timestampToDateCalendar (tms) {
      const dateObjs = new Date(tms)
      const dateObjsObj = dateObjs.toString().split(' ')
      const theMonthObj = (dateObjs.getMonth() + 1)
      const theMonth = (theMonthObj.toString().length === 1) ? '0' + theMonthObj : theMonthObj
      return dateObjs.getFullYear() + '-' + theMonth + '-' + dateObjsObj[2] + 'T' + dateObjsObj[4]
    },
    timestampToDateCalendar64 (tms) {
      const dateObjs = new Date(tms)
      const dateObjsObj = tms.toString().split(' ')
      const theMonthObj = (dateObjs.getMonth() + 1)
      const theMonth = (theMonthObj.toString().length === 1) ? '0' + theMonthObj : theMonthObj
      return dateObjs.getFullYear() + '-' + theMonth + '-' + dateObjsObj[2] + 'T' + dateObjsObj[4]
    },
    intervalFormat (interval) {
      return interval.time
    },
    getUserRights () {
      if (localStorage.getItem('userRights')) {
        const userRights = JSON.parse(JSON.parse(localStorage.getItem('userRights')))
        this.canEditEvents = userRights.edit_bookings
        this.canSendMessage = userRights.send_messages
        this.canSeePhone = userRights.see_phone
        this.addPast = userRights.add_past
      }
    },
    showEventModal () {
      this.$bvModal.show('modal')
      this.$bvModal.hide('holiday')
      this.$bvModal.hide('meet')
      this.meetingId = null
    },
    showHolidayModal () {
      this.$bvModal.show('holiday')
      this.$bvModal.hide('modal')
      this.$bvModal.hide('meet')
      this.meetingId = null
    },
    showMeeting (meetingId) {
      this.meetingId = meetingId
      this.$bvModal.show('meet')
      this.$bvModal.hide('holiday')
      this.$bvModal.hide('modal')
    },
    closeMeet () {
      this.meetingId = false
    },
    addDiscount () {
      this.getCompanyDiscounts()
      this.$bvModal.show('theDiscount')
    },
    getCompanyDiscounts () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_ACTIVE_VOUCHER)
        .then((response) => {
          if (response.data.status === 'success') {
            this.companyDiscounts = response.data.vouchers
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    setDiscount (discount) {
      this.addDiscountValue = true
      this.$refs.eventPriceDiscountToken.innerHTML = discount.token

      let theDiscount = discount.value
      if (discount.value_type === '%') {
        theDiscount = (discount.value / 100 * parseInt(this.$refs.eventPriceSubtotal.innerHTML)).toFixed(2)
      }

      this.$refs.eventPriceDiscount.innerHTML = '-' + theDiscount + ' ' + this.priceCurrency

      this.$refs.eventPrice.innerHTML = (parseFloat(this.$refs.eventPriceSubtotal.innerHTML) - theDiscount) + ' ' + this.priceCurrency

      this.$bvModal.hide('theDiscount')
    },
    removeDiscount () {
      this.$refs.eventPriceDiscountToken.innerHTML = ''
      this.$refs.eventPriceDiscount.innerHTML = '0 ' + this.priceCurrency
      this.$refs.eventPrice.innerHTML = this.$refs.eventPriceSubtotal.innerHTML
      this.addDiscountValue = false
    },
    previewImage: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.logo = e.target.result
      }
      reader.readAsDataURL(this.$refs.file.files[0])
    },
    previewImageNew: function (task) {
      const reader = new FileReader()
      reader.onload = (e) => {
        task.logo = e.target.result
      }
      reader.readAsDataURL(this.$refs.fileNew.files[0])
    },
    getHistoryRecord () {
      const postData = {
        company: this.companyToken,
        patient: this.$refs.customerToken.value
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_PATIENT_RECORD, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.itemsPatientRecords = response.data.templates
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    templatesDisplay () {
      const postData = {
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_TEMPLATES, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.itemsPatient = response.data.templates
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
        // set empty first
    },
    saveTemplate () {
      if (this.templateName === '' || this.templateData === '') {
        alert(this.$t('customer_.mandatory_fields'))
        return
      }
      const postData = {
        token: this.templateId,
        name: this.templateName,
        company: this.companyToken,
        doctor: '',
        template: this.templateData
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_COMPANY_TEMPLATES, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.itemsPatient = response.data.templates
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    deleteTemplate () {
      this.$bvModal.msgBoxConfirm(this.$t('customer_.dyw_delete_template'))
        .then(value => {
          if (value === true) {
            const postData = {
              company: this.companyToken,
              token: this.templateId
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_COMPANY_TEMPLATES, postData)
              .then((response) => {
                if (response.data.status === 'success') {
                  alert('Template sters cu success!')
                  this.itemsPatient = response.data.templates
                }
              })
              .catch((error) => {
                console.error(error.response)
              })
          }
        })
    },
    setCurrentTemplate (item) {
      this.templateId = item.token
      this.templateName = item.name
      this.templateData = item.template
    },
    readCurrentRecord (item) {
      this.recordId = item.token
      this.recordData = item.record
    },
    addNewTemplate () {
      this.templateId = ''
      this.templateName = ''
      this.templateData = ''
      this.selectedItem = ''
    },
    selectTemplate () {
      if (this.selectedItemTemplate !== '') {
        this.templateSelected = this.selectedItemTemplate
        this.selectedItemTemplateData = this.refineTemplate(this.templateSelected)
      }
    },
    getTemplateDataById (id) {
      for (let x = 0; x < this.itemsPatient.length; x++) {
        if (this.itemsPatient[x].token === id) {
          // console.log(this.itemsPatient[x].template)
          return this.itemsPatient[x].template
        }
      }
    },
    refineTemplate (id) {
      return this.getTemplateDataById(id)
    },
    saveRecord () {
      console.log('saveRecord', this.$refs.eventID.value)
      const postData = {
        booking: this.$refs.eventID.value,
        template: this.templateSelected,
        record: this.selectedItemTemplateData
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SAVE_PATIENT_RECORD, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('customer_.record_saved'))
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    getSavedDiagnosis () {
      const search = {
        booking: this.$refs.eventID.value,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_PATIENT_DIAGNOSIS, search)
        .then((response) => {
          if (response.data.status === 'success') {
            const currentDiagnosis = JSON.parse(response.data.current_diagnosis.diagnosis)
            const companyDiagnosis = response.data.company_diagnosis
            this.chips = currentDiagnosis
            this.someitems = companyDiagnosis
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getSavedRecord () {
      this.selectedItemTemplate = ''
      this.templateSelected = ''
      this.selectedItemTemplateData = ''
      const search = {
        booking: this.$refs.eventID.value
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_PATIENT_RECORD, search)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.templates.length) {
              const patientRecord = response.data.templates[0]
              this.selectedItemTemplate = patientRecord.template
              this.templateSelected = patientRecord.template
              this.selectedItemTemplateData = patientRecord.record
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    printRecord () {
      console.log(this.selectedItemTemplateData)
      this.saveRecord()
      const customCSS = '<style>.ql-align-center{text-align: center}</style>'
      const winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0')
      winPrint.document.write('<br /><br /><br />' + this.selectedItemTemplateData + customCSS)
      winPrint.document.close()
      winPrint.focus()
      winPrint.print()
      winPrint.close()
    },
    printRecord1 () {
      this.saveRecord()
      const customCSS = '<style>.ql-align-center{text-align: center}</style>'
      const winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0')
      winPrint.document.write('<br /><br /><br />' + this.recordData + customCSS)
      winPrint.document.close()
      winPrint.focus()
      winPrint.print()
      winPrint.close()
    }
  }
}
</script>

<style lang="scss">
.v-calendar-daily_head-weekday,
.v-calendar-daily_head-day-label{
  display: none !important;
}
.calendar-time{
  font-size: 15px !important;
  font-weight: bold !important;
}
.v-autocomplete__content{
  z-index: 999 !important;
}
.isLoadingEvent,
.isLoading{
  background: #000000e6;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #19bdb4;
  width: 120px !important;
  height: 120px !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.isWorking{
  background: #c6eefd;
}
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.new_customer{
  color: #ff8d00;
  font-weight: bold;
}
.haspayed label{
  margin-right: 10px;
  font-size: 14px;
}
.change{
  padding: 2px;
  font-size: 12px;
}
.send_message{
  cursor: pointer;
}
.send_message:hover{
  text-decoration: underline;
}
.employee_logo_new{
  height: 40px;
  width: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 2px;
  margin-right: 4px;
}
.employee_logo{
  height: 100px;
  width: 100px;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: cover;
  border-radius: 20px;
}
.employeeName{
  font-size:16px;
  font-weight: bold
}
.employeeDetails {
  margin: 5px 0 !important;
}
.employeeDetails .card:hover{
  cursor: pointer;
  background: #c6eefd;
}
.calendarDay{
  float: left;
  width: 200px;
  border-right: 1px solid #000;
}
.calendarDay .tui-full-calendar-timegrid-container{
  overflow: hidden;
}
.calendarDay .tui-full-calendar-dayname-layout,
.calendarDay .tui-full-calendar-vlayout-area > div:first-child
{
  display: none;
}

.calendarDayCustomer{
  height: 50px;
  width: auto;
  overflow: hidden;
  background: var(--iq-primary);
  color: #fff;
  text-align: center;
}
.calendarDayCustomer img{
  height: 40px;
  width: 40px;
  border: 2px solid #666;
  border-radius: 40px;
  margin: 2px;
}
.customerFavorit{
  margin-top: -25px;
  position: absolute;
  margin-left: -25px;
}
.customerFavorit i{
  cursor: pointer;
  font-size: 20px;
}
.customerFavorit i.ri-star-fill{
  color: #27b345;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
.employeesCalendarImage{
  height: 100px;
}
.onHoldEvent{
  border-top: 1px solid lightgrey;
  &:hover{
    background: #83deff;
    cursor: pointer;
  }
  &.activeOnHold{
    background: #28a745;
    color: #fff;
  }
}
.breakperiod{
  background: #e0dfdf;
  background-size: 100%;
  width: 100%;
  text-align:center;
  color: #0c0c0c;
  font-size: 11px;
  font-weight: bold;
}
.v-calendar.v-calendar-category{
  width: auto;
  overflow-x: auto;
}
.v-calendar-daily__intervals-body,
.v-calendar-daily__intervals-head{
  background: white;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 9;
}
.v-calendar.v-calendar-category .v-calendar-daily__head,
.v-calendar.v-calendar-category .v-calendar-daily__body,
.v-calendar-daily__scroll-area,
.v-calendar-daily__pane,
.v-calendar-daily__day-container{
  overflow: unset !important;
}
.v-calendar-daily__pane{
  height: auto !important;
}

#hourTooltip{
  position: fixed;
  background: #27b345;
  color: white;
  padding: 5px 10px;
  z-index: 99999;
  margin-left: 10px;
  margin-top: 10px;
}

.eventTime{
  font-size:10px;
}
.eventCustomer{
  font-weight: bold;
  font-size: 11px;
}
.eventCustomer i{
  display: inline-block;
  margin-top: -3px;
}
.eventService{
  font-size: 11px;
}
.eventStatus{
  position: absolute;
  right: 2px;
  top: -2px;
  font-size: 11px;
}
.customerFavorit i{
  display: inline-block;
  margin-top: -9px;
}
.activeServices{
  font-size: 12px;
}
.eventPriceTotal{
  border-top: 1px solid #c5c5c5;
  display: inline-block;
  margin-top:2px;
  padding-top:2px;
  margin-bottom: 5px;
}
.addDiscount{
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  margin-left: 7px;
}
.eventPriceDiscountToken{
  color: #ff7600;
  font-size: 11px;
  font-weight: bold;
}
.addedBy{
  font-size: 11px;
  text-decoration: underline;
  display: inline-block;
}
.description {
  line-height: 20px !important;
}
.diagnostic .v-input__control{
  position: absolute;
  width: 100%;
  left: 0;
}
.diagnostic .fa-times-circle:before {
  content: "\00d7";
}
@media only screen and (max-width: 767px){
  .v-calendar-category .v-calendar-daily_head-day-label{
    position: sticky;
    left: 0;
    z-index: 9;
    width: 30px;
    margin-left: -59px;
    margin-top: 20px;
    display: none;
  }
  .v-calendar-category .v-calendar-daily_head-day-label .v-btn{
    position: absolute;
    left: 0;
    top: 40px;
    background: #1976d2 !important;
    color: #fff !important;
  }
  .v-calendar-category .v-calendar-daily_head-weekday{
    position: absolute;
    left: -45px;
    top: 25px;
    padding: 5px;
    z-index: 99;
    display: none;
  }
  .v-calendar-category .v-calendar-daily_head-day{
    margin-top: -15px;
  }
}

@media only screen and (max-width: 556px){
  .searchBookingMobile{
    position: absolute !important;
    right: 10px;
    width: 180px !important;
  }
}
@media only screen and (max-width: 556px){
  .v-calendar-category .v-calendar-daily_head-day-label {
    width: 57px !important;
  }
  .v-calendar-daily_head-day-label {
    width: 100% !important;
  }
  .theCategoryClass{
    font-size: 15px !important;
  }
}
.theCategoryClass{
  line-height: 20px !important;
  padding: 10px 0;
}
.searchresults:hover{
  background: #27b345;
  color: white;
  cursor: pointer;
}

.modal___BV_modal_content_{ box-shadow: 0px 0px 7px 11px #989898 !important; }
.serviceDescription{
  font-size: 12px;
}
.serviceDuration{
  font-size: 12px;
  color: #007d15;
  font-weight: bold;
}
.servicePrice{
  font-size: 12px;
  color: #007d15;
  font-weight: bold;
}
</style>
